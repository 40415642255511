import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  GridCellExpand,
  ModalPopUp,
  FormTextField as MyTextInput,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  ConfirmDialog,
  ModalCloseBtn,
  MuiAutocomplete,
} from "../../../components";
// redux
import { getMentor } from "../../../features/mentor/mentorSlice";
import { getAccount } from "../../../features/customer/accountSlice";
import {
  createMentorMapping,
  getMentorMapping,
  getUnMappedClients,
  updateMentorMapping,
  deleteMentorMapping,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../features/mentor/mentorMappingSlice";

// -------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  mentor: Yup.object().required("Mentor Name is required"),
  account: Yup.object().required("Account Name is required"),
  custClient: Yup.object().required("Customer Client Name is required"),
  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  mentor: Yup.string().uuid().required("Mentor Name is required"),
  account: Yup.string().required("Account Name is required"),
  custClient: Yup.string().uuid().required("Customer Client Name is required"),
  comments: Yup.string(),
});

// -----------------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// --------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },
  filter_grid : {
    [theme.breakpoints.down('md')]: {
      display:'flex !important',
      flexDirection:'column !important'
  
    },
  },
  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))



const MentorMapping = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [engName, setEngName] = useState("");

  const location = useLocation()
  const dispatch = useDispatch();
  const { message, errMessage, mentorMappingData, unMappedClientsData, selectedData } =
    useSelector((state) => ({
      ...state.mentorMapping,
    }));

  const { mentorsData } = useSelector((state) => ({ ...state.mentor }));

  const { accountData } = useSelector((state) => ({ ...state.account }));

  function getEngagementName(params) {
    return <GridCellExpand value={params?.row?.clientEngagement?.engagementName} width={params.colDef.computedWidth} />
  }

  function getCustClientName(params) {
    return `${params?.row?.excoCustomerUser[0]?.excoCustomerUserDetail?.firstName} ${params?.row?.excoCustomerUser[0]?.excoCustomerUserDetail?.lastName}`;
  }
  function getMentorName(params) {
    return <GridCellExpand value={`${params?.row?.excoMentor?.excoMentorDetail?.firstName}
    ${params?.row?.excoMentor?.excoMentorDetail?.lastName}` || ''} width={params.colDef.computedWidth} />
  }

  function getAccountName(params) {
    return params?.row?.excoCustomerUser[0]?.excoAccount?.accountName;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }  

  const data = {
    rows: mentorMappingData,
    columns: [
      {
        field: "mentorName",
        headerName: "Mentor Name",
        width: 200,
        renderCell: getMentorName,
        valueGetter: (params) => `${params?.row?.excoMentor?.excoMentorDetail?.firstName} ${params?.row?.excoMentor?.excoMentorDetail?.lastName}`
      },
      {
        field: "accountName",
        headerName: "Account Name",
        width: 200,
        valueGetter: getAccountName,
      },
      {
        field: "customerClientName",
        headerName: "Customer Client Name",
        width: 200,
        valueGetter: getCustClientName,
      },
      {
        field: "engagementName",
        headerName: "Engagement Name",
        width: 200,
        renderCell: getEngagementName,
        valueGetter: (params) => params?.row?.clientEngagement?.engagementName
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand
      },
    ],
  };

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    setEngName("")
    formik.resetForm({
      values: {
        mentor: "",
        account: "",
        custClient: "",
        engagement: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
    setEngName(selectedData?.clientEngagement?.engagementName)
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

 const showDelete = () =>
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleDeleteMentorMapping(),
            })

  const handleDeleteMentorMapping = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteMentorMapping(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getMentorMapping());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      mentor: "",
      account: "",
      custClient: "",
      engagement: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createMentorMapping({
          excoMentorId: values.mentor?.excoMentor.id,
          excoCustomerUserId: values.custClient?.id,
          clientEngagementId: values.engagement,
          comment: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getMentorMapping());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      mentor: selectedData?.excoMentor?.id,
      account:
        Object.keys(selectedData).length > 0 &&
        selectedData?.excoCustomerUser.length > 0 &&
        selectedData?.excoCustomerUser[0]?.excoAccount?.accountName,
      custClient:
        Object.keys(selectedData).length > 0 &&
        selectedData?.excoCustomerUser.length > 0 &&
        selectedData?.excoCustomerUser[0].id,
      engagement: selectedData?.clientEngagement?.id,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateMentorMapping({
          id: selectedData?.id,
          excoMentorId: values.mentor,
          clientEngagementId: values?.engagement,
          comment: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getMentorMapping());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getMentorMapping());
    dispatch(getMentor());
    dispatch(getAccount());
  }, []);

  useEffect(() => {
    (formik?.values?.account?.id || formikEdit?.values?.account?.id) &&
      dispatch(
        getUnMappedClients(
          formik.values.account?.id || formikEdit?.values?.account?.id
        )
      );
  }, [formik.values.account?.id, formikEdit?.values?.account?.id]);

  useEffect(() => {
    formik?.values?.custClient &&
      unMappedClientsData.map((item) => {
        if (item?.id === formik?.values?.custClient?.id) {
          let setField = formik.setFieldValue(
            "engagement",
            item?.clientEngagement?.id
          );
          let setEng = setEngName(
            item?.clientEngagement?.engagementName
          );
          return setField, setEng;
        }
      });
  }, [formik.values?.custClient]);

  useEffect(() => {
    formikEdit?.values?.custClient &&
      unMappedClientsData.map((item) => {
        if (item?.id === formikEdit?.values?.custClient) {
          let setField = formikEdit.setFieldValue(
            "engagement",
            item?.clientEngagement.id
          );
          let setEng = setEngName(
            item?.clientEngagement?.engagementName
          );
          return setField, setEng;
        }
      });
  }, [formikEdit.values?.custClient]);


  const classes = useStyles();
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader"> Mentor Mapping</PageHeader>
        <MuiDataGrid
           setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
           setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }
          toolbarColumn = {true}
          toolbarFilter = {true}
          toolbarDensity = {true}
          toolbarExport = {true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />

          <div className={classes.responsiveText}>
            <MuiAutocomplete
              name="mentor"
              options={mentorsData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.mentor}
              optionValue="firstName"
              optionSecValue="lastName"
              label="Mentor Name"
              required={true}
              error={formik.touched.mentor && Boolean(formik.errors.mentor)}
              helperText={formik.touched.mentor && formik.errors.mentor}
            />
            <MuiAutocomplete
              name="account"
              options={accountData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.account}
              optionValue="accountName"
              label="Account Name"
              required={true}
              error={formik.touched.account && Boolean(formik.errors.account)}
              helperText={formik.touched.account && formik.errors.account}
            />
            <MuiAutocomplete
              name="custClient"
              options={unMappedClientsData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.custClient}
              innerValue={true}
              optionValue="excoCustomerUserDetail"
              optionSecValue="firstName"
              optionThirdValue="lastName"
              label="Customer Client Name"
              required={true}
              error={formik.touched.custClient && Boolean(formik.errors.custClient)}
              helperText={formik.touched.custClient && formik.errors.custClient}
            />

            <MyTextInput
              label="Engagement Name"
              type="text"
              name="engagement"
              required={true}
              fullWidth={true}
              margin="normal"
              id="engagement"
              value={engName}
              disabled={true}
              error={
                formik.touched.engagement && Boolean(formik.errors.engagement)
              }
              helperText={formik.touched.engagement && formik.errors.engagement}
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>
          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />

          <div className={classes.responsiveText}>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Mentor Name *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="mentor"
                defaultValue={formikEdit?.values?.mentor?.toString()}
                value={formikEdit.values.mentor}
                error={
                  formikEdit.touched.mentor && Boolean(formikEdit.errors.mentor)
                }
                label="Mentor Name"
                onChange={formikEdit.handleChange}
              >
                {mentorsData.length > 0 &&
                  mentorsData.map((item) => (
                    <MenuItem key={item.id} value={item.excoMentor.id}>
                      {item.firstName}&nbsp;{item.lastName}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.mentor && formikEdit.errors.mentor}
              </FormHelperText>
            </FormControl>
            <MyTextInput
              label="Account Name"
              type="text"
              name="account"
              required={true}
              fullWidth={true}
              margin="normal"
              id="account"
              value={formikEdit?.values?.account}
              disabled={true}
              error={
                formikEdit.touched.account && Boolean(formikEdit.errors.account)
              }
              helperText={formikEdit.touched.account && formikEdit.errors.account}
            />
            <MyTextInput
              label="Customer Client Name"
              type="text"
              name="custClient"
              required={true}
              fullWidth={true}
              margin="normal"
              id="custClient"
              value={Object.keys(selectedData).length > 0 &&
                selectedData?.excoCustomerUser.length > 0 &&
                `${selectedData?.excoCustomerUser[0].excoCustomerUserDetail?.firstName} ${selectedData?.excoCustomerUser[0]?.excoCustomerUserDetail?.lastName}`}
              disabled={true}
              error={
                formikEdit.touched.custClient && Boolean(formikEdit.errors.custClient)
              }
              helperText={formikEdit.touched.custClient && formikEdit.errors.custClient}
            />
            <MyTextInput
              label="Engagement Name"
              type="text"
              name="engagement"
              required={true}
              fullWidth={true}
              margin="normal"
              id="engagement"
              value={engName}
              disabled={true}
              error={
                formikEdit.touched.engagement && Boolean(formikEdit.errors.engagement)
              }
              helperText={formikEdit.touched.engagement && formikEdit.errors.engagement}
            />
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default MentorMapping;
