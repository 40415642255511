import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { countries, continents } from "countries-list";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import Layout from "../../../../layout/Layout";
import {
  MuiDataGrid,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormSubmitButton,
  MuiAutocomplete,
  FormTextField as MyTextInput,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
} from "../../../../components";
import MicrositeConfirmDialog from '../../microsites/MicrositeConfirmDialog'
// redux
import { getCustomer } from "../../../../features/customer/customerSlice";
import { getAllExec, getAllAM } from "../../../../features/auth/authSlice";
import {
  createAccount,
  getAccount,
  updateAccount,
  deleteAccount,
  rowSelect,
  resetMessage,
  resetErrMessage,
} from "../../../../features/customer/accountSlice";

// ----------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  customer: Yup.object().required("Customer Name is Required"),
  accountName: Yup.string().required("Account  Name is required"),
  geography: Yup.object().required("Geography is required"),
  country: Yup.object().required("Country is required"),
  registrationNo: Yup.string(),
  taxNo: Yup.string(),
  contactName: Yup.string().required("Contact Name is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email( "Email is not valid"),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  comments: Yup.string(),
});

const editValidationSchema = Yup.object({
  customer: Yup.object().required("Customer Name is Required"),
  accountName: Yup.string().required("Account  Name is required"),
  geography: Yup.string().required("Geography is required"),
  country: Yup.string().required("Country is required"),
  registrationNo: Yup.string(),
  taxNo: Yup.string(),
  contactName: Yup.string().required("Contact Name is required"),
  contactEmail: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  comments: Yup.string(),
});

// ------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },
  filter_grid : {
    [theme.breakpoints.down('md')]: {
      display:'flex !important',
      flexDirection:'column !important'
  
    },
  },
  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))



const Account = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
const [countryArr, setCountryArr] = useState([])

  const location = useLocation();

  const dispatch = useDispatch();
  const { message, errMessage, accountData, selectedData } =
    useSelector((state) => ({
      ...state.account,
    }));

  const { customersData } = useSelector((state) => ({ ...state.customer }));
  const { execData, amData } = useSelector((state) => ({ ...state.auth }));

  function getCustName(params) {
    return <GridCellExpand value={params?.row?.excoCustomer?.customerName || ""} width={params.colDef.computedWidth} />;
  }

  function getDialContact(params) {
    return `${params.row.dialingCode || ""}-${params.row.contactNumber || ""}`;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  } 

  const data = {
    rows: accountData,
    columns: [
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 150,
        renderCell: getCustName,
        valueGetter: (params) => params?.row?.excoCustomer?.customerName
      },
      {
        field: "accountName",
        headerName: "Account Name",
        width: 250,
      },
      {
        field: "geoName",
        headerName: "Geography",
        width: 150,
      },
      {
        field: "country",
        headerName: "Country",
        width: 150,
      },
      {
        field: "currency",
        headerName: "Currency",
        width: 150,
      },
      {
        field: "registrationNo",
        headerName: "Registration No.",
        width: 150,
      },
      {
        field: "taxNo",
        headerName: "Tax No.",
        width: 150,
      },
      {
        field: "contactName",
        headerName: "Contact Name",
        width: 150,
      },
      {
        field: "contactEmail",
        headerName: "Contact Email",
        width: 250,
        renderCell: renderCellExpand
      },
      {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 250,
        valueGetter: getDialContact,
      },
      {
        field: "accountExec",
        headerName: "Account Exec",
        width: 200,
      },
      {
        field: "accountManager",
        headerName: "Account Manager",
        width: 200,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 400,
        renderCell: renderCellExpand
      },
    ],
  };

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        customer: "",
        accountName: "",
        geography: "",
        country: "",
        currency: "",
        registrationNo: "",
        taxNo: "",
        contactName: "",
        contactEmail: "",
        dialingCode: "",
        contactNumber: "",
        accountExec: "",
        accountManager: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: (password) => handleDeleteAccount(password),
            })

  const handleDeleteAccount = (password) => {
    let data = {
      id: selectedData?.id,
      password: password
    };
    dispatch(deleteAccount(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getAccount());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      customer: "",
      accountName: "",
      geography: "",
      country: "",
      currency: "",
      registrationNo: "",
      taxNo: "",
      contactName: "",
      contactEmail: "",
      dialingCode: "",
      contactNumber: "",
      accountExec: "",
      accountManager: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createAccount({
          excoCustomerId: values.customer.id,
          accountName: values.accountName,
          geoName: values.geography?.code,
          country: values.country?.name,
          currency: values.currency,
          registrationNo: values.registrationNo,
          taxNo: values.taxNo,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          dialingCode: values.dialingCode,
          contactNumber: values.contactNumber.toString(),
          accountExec:
            Object.keys(values?.accountExec).length > 0
              ? `${values.accountExec?.firstName} ${values?.accountExec?.lastName}`
              : "",
          accountManager:
            Object.keys(values?.accountManager).length > 0
              ? `${values.accountManager?.firstName} ${values?.accountManager?.lastName}`
              : "",
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getAccount());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      customer: selectedData?.excoCustomer,
      accountName: selectedData?.accountName,
      geography: selectedData?.geoName,
      country: selectedData?.country,
      currency: selectedData?.currency,
      registrationNo: selectedData?.registrationNo,
      taxNo: selectedData?.taxNo,
      contactName: selectedData?.contactName,
      contactEmail: selectedData?.contactEmail,
      dialingCode: selectedData?.dialingCode,
      contactNumber: selectedData?.contactNumber,
      accountExec: selectedData?.accountExec,
      accountManager: selectedData?.accountManager,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateAccount({
          id: selectedData?.id,
          excoCustomerId: values.customer?.id,
          accountName: values.accountName,
          geoName: values.geography,
          country: values.country,
          currency: values.currency,
          registrationNo: values.registrationNo,
          taxNo: values.taxNo,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          dialingCode: values.dialingCode,
          contactNumber: values.contactNumber.toString(),
          accountExec: values?.accountExec,
          accountManager: values?.accountManager,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getAccount());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getCustomer());
    dispatch(getAllExec());
    dispatch(getAllAM())
  }, []);

  useEffect(() => {
return formik?.values?.customer && formik?.setFieldValue("accountName", `${formik?.values?.customer?.customerName.split(" ")[0]}-`)
  }, [formik?.values?.customer])

  useEffect(() => {
    let countries = []
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return (
          countries.push(option)
        );
      }
      setCountryArr(countries)
    });
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return (
          formik?.setFieldValue("dialingCode", option?.phone),
          formik?.setFieldValue("currency", option?.currency)
        );
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formikEdit?.values?.country) {
        return (
          formikEdit?.setFieldValue("dialingCode", option?.phone),
          formikEdit?.setFieldValue("currency", option?.currency)
        );
      }
    });
  }, [formikEdit?.values?.country]);
  const classes = useStyles();
  return (
    <Layout>
      <>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ width: "100%" }}>
            <PageHeader variant="pageHeader">Accounts</PageHeader>
          </Box>
          <Link
            to="/exco/customers/account-container"
            state={{
              l3Menu: location?.state?.l3Menu
            }}
          >
            <CancelIcon color="red" />
          </Link>
        </Stack>
        <MuiDataGrid
          setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
          setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }
          toolbarColumn = {true}
          toolbarFilter = {true}
          toolbarDensity = {true}
          toolbarExport = {true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />

          <div className={classes.responsiveText}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="customer"
                  options={customersData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.customer}
                  optionValue="customerName"
                  label="Customer Name"
                  required={true}
                  error={
                    formik.touched.customer && Boolean(formik.errors.customer)
                  }
                  helperText={formik.touched.customer && formik.errors.customer}
                />
                <MuiAutocomplete
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  optionSecValue="name"
                  label="Geography"
                  required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={formik.touched.geography && formik.errors.geography}
                />
                <MyTextInput
                  label="Registration Number"
                  type="text"
                  name="registrationNo"
                  fullWidth={true}
                  margin="normal"
                  id="registrationNo"
                  value={formik.values.registrationNo}
                  onChange={formik.handleChange}
                  tabIndex={5}
                  error={
                    formik.touched.registrationNo &&
                    Boolean(formik.errors.registrationNo)
                  }
                  helperText={
                    formik.touched.registrationNo &&
                    formik.errors.registrationNo
                  }
                />
                <MyTextInput
                  label="Contact Name"
                  type="text"
                  name="contactName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactName"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  tabIndex={7}
                  error={
                    formik.touched.contactName &&
                    Boolean(formik.errors.contactName)
                  }
                  helperText={
                    formik.touched.contactName && formik.errors.contactName
                  }
                />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formik.values.dialingCode}
                      disabled
                      error={
                        formik.touched.dialingCode &&
                        Boolean(formik.errors.dialingCode)
                      }
                      helperText={
                        formik.touched.dialingCode && formik.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Contact Number"
                      type="number"
                      name="contactNumber"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      tabIndex={10}
                      error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                      }
                      helperText={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                <MuiAutocomplete
                  name="accountExec"
                  options={execData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.accountExec}
                  optionValue="firstName"
                  optionSecValue="lastName"
                  label="Account Exec"
                  error={
                    formik.touched.accountExec &&
                    Boolean(formik.errors.accountExec)
                  }
                  helperText={
                    formik.touched.accountExec && formik.errors.accountExec
                  }
                />
                
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Account Name"
                  type="text"
                  name="accountName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="accountName"
                  value={formik.values.accountName}
                  onChange={formik.handleChange}
                  tabIndex={2}
                  error={
                    formik.touched.accountName &&
                    Boolean(formik.errors.accountName)
                  }
                  helperText={
                    formik.touched.accountName && formik.errors.accountName
                  }
                />
                <MuiAutocomplete
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  optionValue="name"
                  label="Country"
                  required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
                <MyTextInput
                  label="Tax Number"
                  type="text"
                  name="taxNo"
                  fullWidth={true}
                  margin="normal"
                  id="taxNo"
                  value={formik.values.taxNo}
                  onChange={formik.handleChange}
                  tabIndex={6}
                  error={formik.touched.taxNo && Boolean(formik.errors.taxNo)}
                  helperText={formik.touched.taxNo && formik.errors.taxNo}
                />
                <MyTextInput
                  label="Contact Email"
                  type="email"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  tabIndex={8}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />
                <MuiAutocomplete
                  name="accountManager"
                  options={amData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.accountManager}
                  optionValue="firstName"
                  optionSecValue="lastName"
                  label="Account Manager"
                  error={
                    formik.touched.accountManager &&
                    Boolean(formik.errors.accountManager)
                  }
                  helperText={
                    formik.touched.accountManager &&
                    formik.errors.accountManager
                  }
                />
                <MyTextInput
                  label="Comments"
                  type="email"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  tabIndex={8}
                  error={
                    formik.touched.comments && Boolean(formik.errors.comments)
                  }
                  helperText={formik.touched.comments && formik.errors.comments}
                />
              </Grid>
            </Grid>
          </div>
          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 900 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />

          <div className={classes.responsiveText}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  name="customer"
                  options={customersData}
                  setValue={(name, newValue) =>
                    formikEdit.setFieldValue(name, newValue)
                  }
                  value={formikEdit.values.customer}
                  optionValue="customerName"
                  label="Customer Name"
                  required={true}
                  error={
                    formikEdit.touched.customer &&
                    Boolean(formikEdit.errors.customer)
                  }
                  helperText={
                    formikEdit.touched.customer && formikEdit.errors.customer
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="geography"
                    defaultValue={formikEdit?.values?.geography}
                    value={formikEdit.values.geography}
                    error={
                      formikEdit.touched.geography &&
                      Boolean(formikEdit.errors.geography)
                    }
                    label="Geography"
                    onChange={formikEdit.handleChange}
                    tabIndex={3}
                  >
                    {Object.keys(continents).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}-{continents[item]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.geography &&
                      formikEdit.errors.geography}
                  </FormHelperText>
                </FormControl>
                <MyTextInput
                  label="Registration Number"
                  type="text"
                  name="registrationNo"
                  fullWidth={true}
                  margin="normal"
                  id="registrationNo"
                  value={formikEdit.values.registrationNo}
                  onChange={formikEdit.handleChange}
                  tabIndex={5}
                  error={
                    formikEdit.touched.registrationNo &&
                    Boolean(formikEdit.errors.registrationNo)
                  }
                  helperText={
                    formikEdit.touched.registrationNo &&
                    formikEdit.errors.registrationNo
                  }
                />
                <MyTextInput
                  label="Contact Name"
                  type="text"
                  name="contactName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactName"
                  value={formikEdit.values.contactName}
                  onChange={formikEdit.handleChange}
                  tabIndex={7}
                  error={
                    formikEdit.touched.contactName &&
                    Boolean(formikEdit.errors.contactName)
                  }
                  helperText={
                    formikEdit.touched.contactName &&
                    formikEdit.errors.contactName
                  }
                />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="dialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="dialingCode"
                      value={formikEdit.values.dialingCode}
                      disabled
                      error={
                        formikEdit.touched.dialingCode &&
                        Boolean(formikEdit.errors.dialingCode)
                      }
                      helperText={
                        formikEdit.touched.dialingCode &&
                        formikEdit.errors.dialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Contact Number"
                      type="number"
                      name="contactNumber"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="contactNumber"
                      value={formikEdit.values.contactNumber}
                      onChange={formikEdit.handleChange}
                      tabIndex={10}
                      error={
                        formikEdit.touched.contactNumber &&
                        Boolean(formikEdit.errors.contactNumber)
                      }
                      helperText={
                        formikEdit.touched.contactNumber &&
                        formikEdit.errors.contactNumber
                      }
                    />
                  </div>
                </div>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                  Account Exec
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="accountExec"
                    defaultValue={formikEdit?.values?.accountExec}
                    value={formikEdit.values.accountExec}
                    error={
                      formikEdit.touched.accountExec &&
                      Boolean(formikEdit.errors.accountExec)
                    }
                    label="Account Exec"
                    onChange={formikEdit.handleChange}
                    tabIndex={3}
                  >
                    {execData.length > 0 && execData.map((item) => (
                      <MenuItem key={item} value={`${item.firstName} ${item.lastName}`}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  {formikEdit.errors.accountExec && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formikEdit.touched.accountExec && formikEdit?.errors.accountExec}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Account Name"
                  type="text"
                  name="accountName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="accountName"
                  value={formikEdit.values.accountName}
                  onChange={formikEdit.handleChange}
                  tabIndex={2}
                  error={
                    formikEdit.touched.accountName &&
                    Boolean(formikEdit.errors.accountName)
                  }
                  helperText={
                    formikEdit.touched.accountName &&
                    formikEdit.errors.accountName
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Country *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    defaultValue={formikEdit?.values?.country}
                    value={formikEdit.values.country}
                    label="Country"
                    onChange={formikEdit.handleChange}
                    tabIndex={4}
                    error={
                      formikEdit.touched.country &&
                      Boolean(formikEdit.errors.country)
                    }
                  >
                    {options.map((option) => {
                      if (formikEdit.values.geography === option.continent) {
                        return (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formikEdit.touched.country && formikEdit.errors.country}
                  </FormHelperText>
                </FormControl>
                <MyTextInput
                  label="Tax Number"
                  type="text"
                  name="taxNo"
                  fullWidth={true}
                  margin="normal"
                  id="taxNo"
                  value={formikEdit.values.taxNo}
                  onChange={formikEdit.handleChange}
                  tabIndex={6}
                  error={
                    formikEdit.touched.taxNo && Boolean(formikEdit.errors.taxNo)
                  }
                  helperText={
                    formikEdit.touched.taxNo && formikEdit.errors.taxNo
                  }
                />
                <MyTextInput
                  label="Contact Email"
                  type="email"
                  name="contactEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactEmail"
                  value={formikEdit.values.contactEmail}
                  onChange={formikEdit.handleChange}
                  tabIndex={8}
                  error={
                    formikEdit.touched.contactEmail &&
                    Boolean(formikEdit.errors.contactEmail)
                  }
                  helperText={
                    formikEdit.touched.contactEmail &&
                    formikEdit.errors.contactEmail
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                  Account Manager
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="accountManager"
                    defaultValue={formikEdit?.values?.accountManager}
                    value={formikEdit.values.accountManager}
                    error={
                      formikEdit.touched.accountManager &&
                      Boolean(formikEdit.errors.accountManager)
                    }
                    label="Account Exec"
                    onChange={formikEdit.handleChange}
                    tabIndex={3}
                  >
                    {amData.length > 0 && amData.map((item) => (
                      <MenuItem key={item.id} value={`${item.firstName} ${item.lastName}`}>
                        {item.firstName} {item.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  {formikEdit.errors.accountManager && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {formikEdit.touched.accountManager && formikEdit?.errors.accountManager}
                    </FormHelperText>
                  )}
                </FormControl>
                <MyTextInput
                  label="Comments"
                  type="email"
                  name="comments"
                  fullWidth={true}
                  margin="normal"
                  id="comments"
                  value={formikEdit.values.comments}
                  onChange={formikEdit.handleChange}
                  tabIndex={8}
                  error={
                    formikEdit.touched.comments &&
                    Boolean(formikEdit.errors.comments)
                  }
                  helperText={
                    formikEdit.touched.comments && formikEdit.errors.comments
                  }
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <MicrositeConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Account;
