import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import forgotPasswordReducer from '../features/auth/forgotPasswordSlice'
import reusableReducer from "../features/common/reusableSlice";
import customerReducer from "../features/customer/customerSlice";
import custUserReducer from "../features/customer/custUserSlice";
import micrositeReducer from "../features/microsite/micrositeSlice";
import mentorReducer from "../features/mentor/mentorSlice";
import mentorMappingReducer from "../features/mentor/mentorMappingSlice";
import accountReducer from "../features/customer/accountSlice";
import custDevAreasReducer from "../features/customer/custDevAreasSlice";
import engCheckinFrequencyReducer from "../features/customer/engCheckinFrequencySlice";
import progressReviewFrequencyReducer from "../features/customer/progressReviewFrequencySlice";
// engagement
import adminEngagementReducer from "../features/engagement/adminEngagementSlice";
// exec role
// customers
import execCustomerReducer from "../features/exec/customers/customerSlice";
// mentors
import execMentorReducer from "../features/exec/mentors/mentorSlice";
// clients
import execClientReducer from "../features/exec/clients/clientSlice";
// opportunity
import opportunityReducer from "../features/exec/opportunity/opportunitySlice";
import oppGoalsReducer from "../features/exec/opportunity/oppGoalsSlice";
import oppScopeReducer from "../features/exec/opportunity/oppScopeSlice";
// engagement
import engagementReducer from "../features/exec/engagement/engagementSlice";
import keyEngagementReducer from "../features/exec/engagement/keyEngagementSlice";
import netPromoterScoreReducer from "../features/exec/engagement/netPromoterScoreSlice";
// nomination
import nominationReducer from "../features/nomination/nominationSlice";
// config
import opportunityStageReducer from "../features/config/opportunityStageSlice";
import opportunityStatusReducer from "../features/config/opportunityStatusSlice";
import contactDesignationReducer from "../features/config/contactDesignationSlice";
import engTierNameReducer from "../features/config/engTierNameSlice";
import engagementDeliverableReducer from "../features/config/engagementDeliverableSlice";
import engagementStatusReducer from "../features/config/engagementStatusSlice";
import engagementStageReducer from "../features/config/engagementStageSlice";
import developmentAreasReducer from "../features/config/developmentAreasSlice";
import npsRatingReducer from "../features/config/npsRatingSlice";
import levelOfEngagementReducer from "../features/config/levelOfEngagementSlice";
import impactScaleRatingReducer from "../features/config/impactScaleRatingSlice";
import nominationModeReducer from "../features/config/nominationModeSlice";
import nominationStatusReducer from "../features/config/nominationStatusSlice";
import l1MenuReducer from '../features/platform/app_setup/menu_management/l1MenuSlice'
import l2MenuReducer from '../features/platform/app_setup/menu_management/l2MenuSlice'
import l3MenuReducer from '../features/platform/app_setup/menu_management/l3MenuSlice'
import apiManagementReducer from "../features/platform/app_setup/apiManagementSlice";
import userGroupsReducer from "../features/platform/role_management/userGroupsSlice";
import rolesReducer from "../features/platform/role_management/rolesSlice";
import l1AccessPermissionReducer from "../features/platform/role_management/access_permissions/l1AccessPermissionSlice";
import l2AccessPermissionReducer from '../features/platform/role_management/access_permissions/l2AccessPermissionSlice';
import l3AccessPermissionReducer from '../features/platform/role_management/access_permissions/l3AccessPermissionSlice';
// summary
import summaryReducer from '../features/summary/summarySlice'
// outcomes
import outcomeReducer from '../features/outcomes/outcomeSlice'
// coaching focus area
import coachingFocusAreaReducer from '../features/coaching_focus_area/coachingFocusAreaSlice'
// notification
import notificationReducer from '../features/notifications/notificationSlice'

// --------------------------------------------------------------------------------

export const store = configureStore({
  reducer: {
    auth: authReducer,
    forgotPass: forgotPasswordReducer,
    common: reusableReducer,
    customer: customerReducer,
    custUser: custUserReducer,
    microsite: micrositeReducer,
    mentor: mentorReducer,
    mentorMapping: mentorMappingReducer,
    nomination: nominationReducer,
    adminEng: adminEngagementReducer,
    execCustomer: execCustomerReducer,
    execMentor: execMentorReducer,
    execClient: execClientReducer,
    opportunity: opportunityReducer,
    oppGoals: oppGoalsReducer,
    oppScope: oppScopeReducer,
    engagement: engagementReducer,
    keyEngagement: keyEngagementReducer,
    netPromoterScore: netPromoterScoreReducer,
    account: accountReducer,
    custDevAreas: custDevAreasReducer,
    engCheckinFrequency: engCheckinFrequencyReducer,
    progressReviewFrequency: progressReviewFrequencyReducer,
    engTierName: engTierNameReducer,
    oppStageName: opportunityStageReducer,
    oppStatus: opportunityStatusReducer,
    contactDesig: contactDesignationReducer,
    engDeliverable: engagementDeliverableReducer,
    engStatus: engagementStatusReducer,
    engStage: engagementStageReducer,
    devAreas: developmentAreasReducer,
    npsRating: npsRatingReducer,
    levelOfEng: levelOfEngagementReducer,
    impactScaleRating: impactScaleRatingReducer,
    nominationStatus: nominationStatusReducer,
    nominationMode: nominationModeReducer,
    l1Menus: l1MenuReducer,
    l2Menus: l2MenuReducer,
    l3Menus: l3MenuReducer,
    apiManagement: apiManagementReducer,
    userGroups: userGroupsReducer,
    roles: rolesReducer,
    l1AccessPermission: l1AccessPermissionReducer,
    l2AccessPermission: l2AccessPermissionReducer,
    l3AccessPermission: l3AccessPermissionReducer,
    summary: summaryReducer,
    outcomes: outcomeReducer,
    coachingFocusArea: coachingFocusAreaReducer,
    notification: notificationReducer
  },
});
