import React, { useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, Notifier, ErrorNotifier, FormTextField as MyTextInput } from "../../../components";
// redux
import {
  updateEngagement,
  resetMessage,
  resetErrMessage,
  getEngagement,
  getNotEngagedClients
} from "../../../features/exec/engagement/engagementSlice";
import { getAccount } from "../../../features/customer/accountSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";
import { getEngagementStatus } from "../../../features/config/engagementStatusSlice";

// ---------------------------------------------------------------------

const validationSchema = Yup.object({
  account: Yup.string()
    .uuid("Enter valid UUID")
    .required("Customer Accounts is required"),
  custClient: Yup.string()
    .uuid("Enter valid id")
    .required("Customer Client Name is required"),
  engagementName: Yup.string().required("Engagement Name is required"),
  engagementScope: Yup.string().required("Engagement Scope is required"),
  engTierName: Yup.string().required("Engagement Tier Name is required"),
  engStartDate: Yup.string().required("Engagement Start Date is required"),
  contactName: Yup.string().required("Contact Name is required "),
  contactEmail: Yup.string()
    .email("Enter valid Email")
    .required("Contact Email is required"),
  dialingCode: Yup.string().required("DialingCode is required"),
  contactNumber: Yup.string().required("Contact Number is required").matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(10, "Must be exactly 10 digits"),
  engagementStatus: Yup.string().required("Engagement Status is required"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------------------

const UpdateEngagement = (props) => {
  const { message, errMessage, notEngagedClientsData } = useSelector(
    (state) => ({ ...state.engagement })
  );

  const { accountData } = useSelector((state) => ({ ...state.account }));
  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));
  const { engagementStatusData } = useSelector((state) => ({
    ...state.engStatus,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      account: props?.values?.excoCustomerUser?.excoAccount?.id,
      custClient: props?.values?.excoCustomerUser?.id,
      engagementName: props?.values?.engagementName,
      engagementScope: props?.values?.engagementScope,
      engTierName: props?.values?.engTierName,
      engStartDate: format(
        new Date(props?.values?.engagementStartDate),
        "yyyy-MM-dd"
      ),
      contactName: props?.values?.contactName,
      contactEmail: props?.values?.contactEmail,
      dialingCode: props?.values?.dialingCode,
      contactNumber: props?.values?.contactNumber,
      engagementStatus: props.values?.engagementStatus,
      comments: props?.values?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateEngagement({
          id: props?.values?.id,
          excoCustomerAccountId: values?.account,
          excoCustomerUserId: values.custClient,
          engagementName: values.engagementName,
          engagementScope: values.engagementScope,
          engTierName: values.engTierName,
          engagementStartDate: values?.engStartDate,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          dialingCode: values.dialingCode.toString(),
          contactNumber: values.contactNumber.toString(),
          engagementStatus: values.engagementStatus,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getEngagement());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getEngTierName());
    dispatch(getAccount());
    dispatch(getEngagementStatus());
  }, []);

  useEffect(() => {
    formik?.values?.account &&
      dispatch(getNotEngagedClients(formik.values.account));
  }, [formik.values.account]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Update Engagement</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={formik.touched.account && formik.errors.account}
              >
                <InputLabel id="demo-simple-select-label">
                  Customer Accounts *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="account"
                  defaultValue={formik?.values?.account}
                  value={formik.values.account}
                  label="Customer Accounts *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {accountData.length > 0 &&
                    accountData.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.accountName}
                      </MenuItem>
                    ))}
                </Select>
                {formik?.errors?.account && (
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    {formik.touched.account && formik.errors.account}
                  </FormHelperText>
                )}
              </FormControl>
              <MyTextInput
                label="Engagement Name"
                type="text"
                name="engagementName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementName"
                value={formik.values.engagementName}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementName &&
                  Boolean(formik.errors.engagementName)
                }
                helperText={
                  formik.touched.engagementName && formik.errors.engagementName
                }
              />
              <FormControl
                sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}
                fullWidth
                error={
                  formik.touched.engTierName && formik.errors.engTierName
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Engagement Tier Name *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="engTierName"
                  defaultValue={formik.values.engTierName}
                  value={formik.values.engTierName}
                  label="Engagement Tier Name *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {engTierNameData.length > 0 &&
                    engTierNameData.map((item) => (
                      <MenuItem key={item.id} value={item.engagementTierName}>
                        {item.engagementTierName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.engTierName && formik.errors.engTierName}
                </FormHelperText>
              </FormControl>
              <MyTextInput
                label="Contact Name"
                type="text"
                name="contactName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
              />
              <MyTextInput
                label="Contact Email"
                type="text"
                name="contactEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactEmail &&
                  Boolean(formik.errors.contactEmail)
                }
                helperText={
                  formik.touched.contactEmail && formik.errors.contactEmail
                }
              />
              <MyTextInput
                label="Comments"
                type="textarea"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.customerClient && formik.errors.customerClient
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Customer Client *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="custClient"
                  defaultValue={formik?.values?.custClient}
                  value={formik.values.custClient}
                  label="Customer Client *"
                  onChange={formik.handleChange}
                  required={true}
                  disabled={true}
                >
                  <MenuItem value={props?.values?.excoCustomerUser?.id}>{props?.values?.excoCustomerUser?.excoCustomerUserDetail?.firstName}&nbsp;{props?.values?.excoCustomerUser?.excoCustomerUserDetail?.lastName}</MenuItem>
                  {notEngagedClientsData.length > 0 &&
                    notEngagedClientsData.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item?.id}
                      >
                        {item.excoCustomerUserDetail?.firstName}&nbsp;{item.excoCustomerUserDetail?.lastName}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.custClient && formik.errors.custClient}
                </FormHelperText>
              </FormControl>

              <MyTextInput
                label="Engagement Scope"
                type="text"
                name="engagementScope"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementScope"
                value={formik.values.engagementScope}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementScope &&
                  Boolean(formik.errors.engagementScope)
                }
                helperText={
                  formik.touched.engagementScope &&
                  formik.errors.engagementScope
                }
              />

              <MyTextInput
                label="Engagement Start Date"
                type="date"
                name="engStartDate"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engStartDate"
                value={formik.values.engStartDate}
                onChange={formik.handleChange}
                tabIndex={5}
                error={
                  formik.touched.engStartDate &&
                  Boolean(formik.errors.engStartDate)
                }
                helperText={
                  formik.touched.engStartDate && formik.errors.engStartDate
                }
              />

              <div style={{ display: "flex" }}>
                <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    label="Dialing Code"
                    type="number"
                    name="dialingCode"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="dialingCode"
                    value={formik.values.dialingCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dialingCode &&
                      Boolean(formik.errors.dialingCode)
                    }
                    helperText={
                      formik.touched.dialingCode && formik.errors.dialingCode
                    }
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <MyTextInput
                    label="Contact Number"
                    type="number"
                    name="contactNumber"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                  />
                </div>
              </div>
              <FormControl
                sx={{ marginTop: "1rem" }}
                fullWidth
                error={
                  formik.touched.engagementStatus &&
                  formik.errors.engagementStatus
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Engagement Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="engagementStatus"
                  defaultValue={formik?.values?.engagementStatus.toString()}
                  value={formik.values.engagementStatus}
                  label="Engagement Status *"
                  onChange={formik.handleChange}
                  required={true}
                >
                  {engagementStatusData.length > 0 &&
                    engagementStatusData.map((item) => (
                      <MenuItem key={item.id} value={item.engagementStatus}>
                        {item.engagementStatus}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.engagementStatus &&
                    formik.errors.engagementStatus}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Box textAlign="center">
            <FormSubmitButton
              type="submit"
              variant="contained"
            >
              Submit
            </FormSubmitButton>
          </Box>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateEngagement;
