import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

// material ui
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// components
import {
  ModalPopUp,
  FormSubmitButton,
  ModalCloseBtn,
  Notifier,
  ErrorNotifier,
  FormTextField as MyTextInput
} from "../../../components";
// redux
import {
  updateNetPromoterScore,
  getCustUserNetPromoterScore,
  resetMessage,
  resetErrMessage,
} from "../../../features/exec/engagement/netPromoterScoreSlice";
import { getNpsRating } from "../../../features/config/npsRatingSlice";
// -----------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  ratingScore: Yup.string().required("Rating Score is required"),
  comments: Yup.string(),
});

// ----------------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ------------------------------------------------------------------------------------------

const UpdateNetPromoterScore = (props) => {
  const dispatch = useDispatch();
  const { selectedData, message, errMessage } =
    useSelector((state) => ({ ...state.netPromoterScore }));

  const { npsRatingData } = useSelector((state) => ({
    ...state.npsRating,
  }));

  const formik = useFormik({
    initialValues: {
      ratingScore: `${selectedData?.npsRatingScore}-${selectedData?.npsRatingName}`,
      comments: selectedData?.comments,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateNetPromoterScore({
          id: selectedData?.id,
          excoCustomerUserId: props?.custClient,
          npsRatingScore: Number(values.ratingScore.split("-")[0]),
          npsRatingName: values.ratingScore.split("-")[1],
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.closeModel();
            dispatch(
              getCustUserNetPromoterScore({
                excoCustomerUserId: props.custClient,
              })
            );
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getNpsRating());
  }, []);

  return (
    <>
      <ModalPopUp open={props.showEdit} closeModel={props.closeModel}>
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={props.closeModel} />
          <div style={{ width: "25rem" }}>
          <Grid container >
              <Grid item xs={12} sm={12} md={12}>
            <FormControl
              sx={{ marginTop: "1rem" }}
              fullWidth
              error={
                formik.touched.ratingScore && formik.errors.ratingScore
                  ? true
                  : false
              }
            >
              <InputLabel id="demo-simple-select-label">
                Rating Score *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ratingScore"
                defaultValue={formik?.values?.ratingScore}
                value={formik.values.ratingScore}
                label="Rating Score"
                onChange={formik.handleChange}
                required={true}
              >
                {npsRatingData.length > 0 &&
                  npsRatingData.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        value={`${item.npsRatingScore}-${item.npsRatingName}`}
                      >
                        {item.npsRatingScore}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formik.touched.ratingScore && formik.errors.ratingScore}
              </FormHelperText>
            </FormControl>
            <MyTextInput
              label="Comments"
              type="textarea"
              name="comments"
              required={true}
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
            </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </>
  );
};

export default UpdateNetPromoterScore;
