import React, { useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, MuiAutocomplete, FormTextField as MyTextInput } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import { getAccount } from "../../../features/customer/accountSlice";
import { getEngTierName } from "../../../features/config/engTierNameSlice";
import {
  createEngagement,
  resetMessage,
  resetErrMessage,
  getEngagement,
  getEngagementByAcc,
  getNotEngagedClients
} from "../../../features/exec/engagement/engagementSlice";

// ---------------------------------------------------------------------

const validationSchema = Yup.object({
  account: Yup.object()
    .required("Customer Accounts is required"),
  custClient: Yup.object()
    .required("Customer Client is required"),
  engagementName: Yup.string().required("Engagement Name is required"),
  engagementScope: Yup.string().required("Engagement Scope is required"),
  engTierName: Yup.object().required("Engagement Tier Name is required"),
  engStartDate: Yup.string().required("Engagement Start Date is required"),
  contactName: Yup.string().required("Contact Name is required "),
  contactEmail: Yup.string()
    .email("Enter valid Email")
    .required("Contact Email is required"),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string().required("ContactNumber is required").matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(10, "Must be exactly 10 digits"),
  comments: Yup.string(),
});

// ---------------------------------------------------------------------------------------------

const AddEngagement = (props) => {
  const { message,errMessage, notEngagedClientsData } = useSelector(
    (state) => ({ ...state.engagement })
  );

  const { accountData } = useSelector((state) => ({ ...state.account }));
  const { engTierNameData } = useSelector((state) => ({
    ...state.engTierName,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      account: props?.account,
      custClient: "",
      engagementName: "",
      engagementScope: "",
      engTierName: "",
      engStartDate: "",
      contactName: "",
      contactEmail: "",
      dialingCode: "",
      contactNumber: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createEngagement({
          excoCustomerAccountId: values?.account?.id,
          excoCustomerUserId: values.custClient?.id,
          engagementName: values.engagementName,
          engagementScope: values.engagementScope,
          engTierName: values.engTierName?.engagementTierName,
          engagementStartDate: values?.engStartDate,
          contactName: values.contactName,
          contactEmail: values.contactEmail,
          dialingCode: values.dialingCode.toString(),
          contactNumber: values.contactNumber.toString(),
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            Object.keys(props.account).length > 0 ? dispatch(getEngagementByAcc(props.account.id)) : dispatch(getEngagement());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getEngTierName());
  }, []);

  useEffect(() => {
    formik?.values?.account &&
      dispatch(getNotEngagedClients(formik.values.account?.id));
  }, [formik.values.account]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={<PageHeader variant="pageHeader">Add Engagement</PageHeader>}
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}>
              <MuiAutocomplete
              name="account"
              options={accountData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.account}
              optionValue="accountName"
              label="Customer Accounts"
              required={true}
              error={
                formik.touched.account && Boolean(formik.errors.account)
              }
              helperText={
                formik.touched.account && formik.errors.account
              }
            />
              <MyTextInput
                label="Engagement Name"
                type="text"
                name="engagementName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementName"
                value={formik.values.engagementName}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementName &&
                  Boolean(formik.errors.engagementName)
                }
                helperText={
                  formik.touched.engagementName && formik.errors.engagementName
                }
              />
                 <MuiAutocomplete
              name="engTierName"
              options={engTierNameData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.engTierName}
              optionValue="engagementTierName"
              label="Engagement Tier Name"
              required={true}
              error={
                formik.touched.engTierName && Boolean(formik.errors.engTierName)
              }
              helperText={
                formik.touched.engTierName && formik.errors.engTierName
              }
            />
              <MyTextInput
                label="Contact Name"
                type="text"
                name="contactName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
              />

              <MyTextInput
                label="Contact Email"
                type="text"
                name="contactEmail"
                required={true}
                fullWidth={true}
                margin="normal"
                id="contactEmail"
                value={formik.values.contactEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactEmail &&
                  Boolean(formik.errors.contactEmail)
                }
                helperText={
                  formik.touched.contactEmail && formik.errors.contactEmail
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
 <MuiAutocomplete
              name="custClient"
              options={notEngagedClientsData}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.custClient}
              innerValue={true}
              optionValue="excoCustomerUserDetail"
              optionSecValue="firstName"
              optionThirdValue="lastName"
              label="Customer Client"
              required={true}
              error={
                formik.touched.custClient && Boolean(formik.errors.custClient)
              }
              helperText={
                formik.touched.custClient && formik.errors.custClient
              }
            />
              <MyTextInput
                label="Engagement Scope"
                type="text"
                name="engagementScope"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engagementScope"
                value={formik.values.engagementScope}
                onChange={formik.handleChange}
                error={
                  formik.touched.engagementScope &&
                  Boolean(formik.errors.engagementScope)
                }
                helperText={
                  formik.touched.engagementScope &&
                  formik.errors.engagementScope
                }
              />
              <MyTextInput
                label="Engagement Start Date"
                InputLabelProps={{ shrink: true }}
                type="date"
                name="engStartDate"
                required={true}
                fullWidth={true}
                margin="normal"
                id="engStartDate"
                value={formik.values.engStartDate}
                onChange={formik.handleChange}
                tabIndex={5}
                error={
                  formik.touched.engStartDate &&
                  Boolean(formik.errors.engStartDate)
                }
                helperText={
                  formik.touched.engStartDate && formik.errors.engStartDate
                }
                sx={{mb: 0}}
              />

              <div style={{ display: "flex" }}>
                <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                    label="Dialing Code"
                    type="number"
                    name="dialingCode"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="dialingCode"
                    value={formik.values.dialingCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dialingCode &&
                      Boolean(formik.errors.dialingCode)
                    }
                    helperText={
                      formik.touched.dialingCode && formik.errors.dialingCode
                    }
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <MyTextInput
                    label="Contact Number"
                    type="number"
                    name="contactNumber"
                    required={true}
                    fullWidth={true}
                    margin="normal"
                    id="contactNumber"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                  />
                </div>
              </div>

              <MyTextInput
                label="Comments"
                type="textarea"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
            </Grid>
          </Grid>
          <Box textAlign="center">
            <FormSubmitButton
              type="submit"
              variant="contained"
            >
              Submit
            </FormSubmitButton>
          </Box>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddEngagement;
