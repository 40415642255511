import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Slider,
  Grid,
} from "@mui/material";
// components
import Layout from "../../layout/Layout";
import {
  ModalPopUp,
  ModalCloseBtn,
  MuiAutocomplete,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
} from "../../components";
// redux
import {
  getAllRatingClients,
  resetErrMessage,
  resetMessage,
  getMentorLatestRatingByClientId,
  getMentorRatingDate,
  getMentorRatingDateById,
  emptyingArray,
} from "../../features/outcomes/outcomeSlice";

// -------------------------------------------------------------------------------------------------------------------

const Outcomes = () => {
  const [clientName, setClientName] = useState("");
  const [sliderValue, setSliderValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const {
    message,
    errMessage,
    getAllClientData,
    dateErrorMessageData,
    getClientLatestRatingData,
    getRatingDateData,
  } = useSelector((state) => ({
    ...state.outcomes,
  }));

  const dispatch = useDispatch();

  const marks = [
    {
      value: "0",
      label: "N/A",
    },
    {
      value: "20",
      label: "-2",
    },
    {
      value: "40",
      label: "-1",
    },
    {
      value: "60",
      label: "0",
    },
    {
      value: "80",
      label: "1",
    },
    {
      value: "100",
      label: "2",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleSliderChange = (event, newValue) => {
    console.log(newValue);
    setSliderValue(newValue);
  };

  useEffect(() => {
    dispatch(emptyingArray());
    dispatch(getAllRatingClients());
  }, []);

  useEffect(() => {
    setStartDate("");
    setEndDate("");
  }, []);

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
    dispatch(getMentorRatingDate(clientName?.excoCustomerUser?.id));
  };

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);

    setGenerateRowId(e.target.value);
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientProgressReviewId: generateDateValue,
    };

    dispatch(getMentorRatingDateById(body)).then((res) => {
      closeShowModelGenerate();
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setStartDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].ratingData[0].ratingFromDate
        ? format(
            new Date(
              getClientLatestRatingData.length > 0 &&
                getClientLatestRatingData[0].ratingData[0].ratingFromDate
            ),
            "yyyy-MM-dd"
          )
        : ""
    );
    setEndDate(
      getClientLatestRatingData.length > 0 &&
        getClientLatestRatingData[0].ratingData[0].ratingFromDate
        ? format(
            new Date(
              getClientLatestRatingData.length > 0 &&
                getClientLatestRatingData[0].ratingData[0].ratingToDate
            ),
            "yyyy-MM-dd"
          )
        : ""
    );
  }, [getClientLatestRatingData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const useStyles = makeStyles((theme) => ({
    box_margin: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "-0.438rem !important",
        marginTop: "0.5rem",
        width:'21.875rem'
      },
    },
  }));
  useEffect(() => {
    clientName &&
      dispatch(
        getMentorLatestRatingByClientId(clientName?.excoCustomerUser?.id)
      );

    setTimeout(() => {
      dispatch(resetErrMessage());
    }, 2000);
  }, [clientName]);
  const classes = useStyles();
  return (
    <Layout>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",

          fontFamily: "arial",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "700",
                marginTop: "0.75rem",
              }}
              gutterBottom
              variant="h9"
              component="div"
            >
              Impact of Coaching for
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <MuiAutocomplete
              name="clientName"
              options={getAllClientData}
              setValue={(name, newValue) => setClientName(newValue)}
              value={clientName}
              optionValue="firstName"
              optionSecValue="lastName"
              sx={{ margin: 0, width: "11rem" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {clientName && getClientLatestRatingData?.length > 0 && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "3.5rem",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "6.375rem",
                  }}
                  className={classes.box_margin}
                >
                  <TextField
                    type="date"
                    name="startDate"
                    id="demo-simple-select"
                    required={true}
                    value={startDate}
                    defaultValue=""
                    onChange={handleStartChange}
                    disabled={true}
                  />
                  <Typography
                    sx={{ fontSize: "1rem", margin: "0 0.5rem" }}
                    gutterBottom
                    variant="h9"
                    component="div"
                  >
                    To
                  </Typography>
                  <TextField
                    type="date"
                    name="startDate"
                    value={endDate}
                    id="demo-simple-select"
                    required={true}
                    onChange={handleEndChange}
                    disabled={true}
                  />
                </Stack>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            {clientName && getClientLatestRatingData?.length > 0 && (
              <FormSubmitButton
                sx={{ textTransform: "none", marginTop: "0.75rem" }}
                type="submit"
                variant="contained"
                size="small"
                onClick={handleShowPreviousOutcomes}
              >
                Previous Outcomes
              </FormSubmitButton>
            )}
          </Grid>
        </Grid>
      </Stack>
      <ModalPopUp
        open={showModelGenerate}
        handleClose={closeShowModelGenerate}
        closeModel={closeShowModelGenerate}
      >
        <Box sx={{ ...style, width: 700 }} component="form" noValidate>
          <ModalCloseBtn close={closeShowModelGenerate} />

          <div style={{ width: "50%", marginTop: "1rem" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Date</InputLabel>
              <Select
                type="select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="generateDateValue"
                value={generateDateValue}
                label="Geography"
                onChange={handleDateGenerateChange}
              >
                {getRatingDateData.length > 0 &&
                  getRatingDateData.map((itemValue) => {
                    console.log(itemValue.id);
                    return (
                      <MenuItem key={itemValue.id} value={itemValue.id}>
                        {format(
                          new Date(itemValue.ratingFromDate),
                          "MM-dd-yyyy"
                        )}{" "}
                        to{" "}
                        {format(new Date(itemValue.ratingToDate), "MM-dd-yyyy")}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          <Stack sx={{ marginTop: "1rem" }}>
            <FormSubmitButton
              type="submit"
              variant="contained"
              onClick={handleGenerateDateSubmit}
            >
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
      </ModalPopUp>
      {clientName &&
        getClientLatestRatingData?.length > 0 &&
        getClientLatestRatingData[0]?.ratings?.length > 0 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "arial",
              marginTop: "3rem",
            }}
          >
            <Typography
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              Impact Scale Rating
            </Typography>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Stack>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    N/A
                  </span>{" "}
                  Priorities Shifted,and this is no longer applicable
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    -2
                  </span>{" "}
                  Significant deterioration in outcome
                </Typography>

                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    -1
                  </span>{" "}
                  Slight deterioration in outcome
                </Typography>
              </Stack>
              <Stack sx={{ marginLeft: "1rem" }}>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    0
                  </span>{" "}
                  No change in outcome
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    1
                  </span>{" "}
                  Achieved expected outcome
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    2
                  </span>{" "}
                  Greater than expected outcome
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}

      {clientName &&
        getClientLatestRatingData?.length === 0 &&
        getClientLatestRatingData[0]?.ratings?.length === 0 && (
          <Box sx={{ textAlign: "center" }}>
            <h1>No Impact of Coaching Data</h1>
          </Box>
        )}
      {clientName &&
        getClientLatestRatingData?.length > 0 &&
        getClientLatestRatingData[0]?.ratings?.length > 0 &&
        getClientLatestRatingData[0]?.ratings?.map((data) => {
          return (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "arial",
                marginTop: "4rem",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "700", width: "30%" }}
                variant="h6"
                gutterBottom
                component="div"
              >
                {data.coachingFocus}
              </Typography>

              <Stack
                sx={{
                  marginLeft: "2rem",
                  display: "flex",
                  width: "60%",

                  flexDirection: "column",
                  fontFamily: "arial",
                }}
              >
                <TextField
                  sx={{ width: "100%", height: "5rem" }}
                  hiddenLabel
                  variant="filled"
                  value={data.comment}
                  disabled={false}
                />

                <div
                  style={{
                    width: "100%",
                    "& .super-app-theme": {
                      "&:nth-of-type(odd)": {
                        bgcolor: (theme) => theme.palette.primary.light,
                      },
                    },
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: "1rem",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", color: "blue" }}
                      gutterBottom
                      component="div"
                    >
                      Impact
                    </Typography>
                    <Slider
                      sx={{
                        marginLeft: "1rem",
                      }}
                      aria-label="Custom marks"
                      value={data.rating}
                      onChange={handleSliderChange}
                      getAriaValueText={valuetext}
                      step={20}
                      marks={marks}
                      disabled={true}
                    />
                  </Stack>
                  {getClientLatestRatingData?.length > 0 &&
                    getClientLatestRatingData[0]?.ratingData?.length > 0 &&
                    !getClientLatestRatingData[0]?.ratingData[0]
                      ?.isSubmitted && (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontFamily: "arial",
                          marginTop: "1rem",
                        }}
                      ></Stack>
                    )}
                </div>
              </Stack>
            </Stack>
          );
        })}

      {getClientLatestRatingData.length > 0 &&
        !getClientLatestRatingData[0]?.ratingData[0]?.isSubmitted && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          ></Stack>
        )}

      {errMessage && <ErrorNotifier message={errMessage} />}
      {dateErrorMessageData && <ErrorNotifier message={dateErrorMessageData} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Outcomes;
