import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// routes
import {
  CREATE_MICROSITE,
  CURD_MICROSITE,
  DELETE_MICROSITE,
  GET_MICROSITE,
  GET_ALL_CUSTOMER_API,
  STOP_MICROSITE,
  START_MICROSITE
} from "../../utils/routes";

// ---------------------------------------------------------------------------------

export const createMicrosite = createAsyncThunk(
  "microsite/createMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMicrosite = createAsyncThunk(
  "microsite/getMicrosite",
  async (data) => {
    return axios.get(GET_MICROSITE, data, { withCredentials: true });
  }
);

export const stopMicrosite = createAsyncThunk(
  "microsite/stopMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(STOP_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startMicrosite = createAsyncThunk(
  "microsite/startMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(START_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustomerName = createAsyncThunk(
  "microsite/getCustomerName",
  async (data) => {
    return axios.get(GET_ALL_CUSTOMER_API, data, { withCredentials: true });
  }
);

export const updateMicrosite = createAsyncThunk(
  "microsite/updateMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(CURD_MICROSITE, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMicrosite = createAsyncThunk(
  "microsite/deleteMicrosite",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_MICROSITE, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ---------------------------------------------------------------------------------

const micrositeSlice = createSlice({
  name: "microsite",
  initialState: {
    loading: false,
    micrositesData: [],
    customersNameData: [],
    selectedData: {},
    message: "",
    errMessage: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [createMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [createMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [startMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [startMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [startMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [stopMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [stopMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [stopMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [getMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.micrositesData = action?.payload?.data;
    },
    [getMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
    [getCustomerName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustomerName.fulfilled]: (state, action) => {
      state.loading = false;
      state.customersNameData = action?.payload?.data;
    },
    [getCustomerName.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.data?.message;
    },
    [updateMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [updateMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteMicrosite.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteMicrosite.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteMicrosite.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } = micrositeSlice.actions;

export default micrositeSlice.reducer;
