import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { countries, continents } from "countries-list";

// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText, 
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, FormTextField as MyTextInput} from "../../components";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
// redux
import { getNomination, getNominationByCust, updateNomination, resetMessage, resetErrMessage } from "../../features/nomination/nominationSlice";
import {getNominationStatus} from '../../features/config/nominationStatusSlice'
import {getNominationMode} from '../../features/config/nominationModeSlice'


// --------------------------------------------------------------

const validationSchema = Yup.object({
    execFirstName: Yup.string().required("Executive First Name is required"),
    execLastName: Yup.string().required("Executive Last Name is required"),
    execEmail: Yup.string("Enter your email")
      .required("Executive Email is required")
      // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
      .email("Email is not valid"),
      geography: Yup.string().required("Geo Name is required"),
      country: Yup.string().required("Country is required "),
      department: Yup.string().required("Department is required"),
      designation: Yup.string().required("Designation is required"),
      execDialingCode: Yup.string().required("Dialing Code is required"),
    execMobileNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    managerFirstName: Yup.string().required("Manager First Name is required"),
    managerLastName: Yup.string().required("Manager Last Name is required"),
    managerEmail: Yup.string("Enter your email")
      .required("Email is required")
      // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
      .email("Email is not valid"),
    managerContactNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    nominationMode: Yup.string().required("Nomination is required"),
});

//   ------------------------------------------------------------------------------------------

const UpdateNomination = (props) => {
const [countryArr, setCountryArr] = useState([])

  const { message, errMessage, selectedData } = useSelector(
    (state) => ({ ...state.nomination })
  );

  const {nominationStatusData} = useSelector((state) => ({...state.nominationStatus}))

  const { nominationModeData } = useSelector((state) => ({
    ...state.nominationMode,
  }));


  const dispatch = useDispatch();

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getNominationStatus())
    dispatch(getNominationMode())
  }, [])

  const formik = useFormik({
    initialValues: {
      account: selectedData?.excoCustomerAccount?.accountName,
      hrId: `${selectedData?.excoCustomerUser?.excoCustomerUserDetail?.firstName} ${selectedData?.excoCustomerUser?.excoCustomerUserDetail?.lastName}`,
      execFirstName: selectedData?.executiveName.split(' ')[0],
      execLastName: selectedData?.executiveName.split(' ')[1],
      execEmail: selectedData?.executiveEmail,
      geography: selectedData?.geoName,
      country: selectedData?.country,
      department: selectedData?.department,
      designation: selectedData?.designation,
      execDialingCode: selectedData?.executiveDialingCode,
      execMobileNo: selectedData?.executiveMobileNo,
      managerFirstName: selectedData?.managerName.split(' ')[0],
      managerLastName: selectedData?.managerName.split(' ')[1],
      managerEmail: selectedData?.managerEmail,
      managerDialingCode: selectedData?.managerDialingCode,
      managerContactNo: selectedData?.managerMobileNo,
      nominationMode: selectedData?.modeOfNomination,
      comment: selectedData?.comment,
      status: selectedData?.status,
      
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        updateNomination({
          id: selectedData.id,
          status: values?.status,
          comment: values?.comment,
          country: values?.country,
          geoName: values?.geography,
          department: values?.department,
          executiveDialingCode: values?.execDialingCode,
          executiveEmail: values?.execEmail,
          executiveMobileNo: values?.execMobileNo.toString(),
          executiveName: `${values.execFirstName} ${values.execLastName}`,
          managerDialingCode: values?.managerDialingCode,
          managerEmail: values?.managerEmail,
          managerMobileNo: values?.managerContactNo.toString(),
          managerName: `${values.managerFirstName} ${values?.managerLastName}`,
          modeOfNomination: values?.nominationMode,
          designation: values?.designation
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            props.account ? dispatch(getNominationByCust(props?.account?.id)) : dispatch(getNomination());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  useEffect(() => {
    let countries = []
    options.map((option) => {
      if (option.continent === formik?.values?.geography) {
        return countries.push(option)
      }
    });
    setCountryArr(countries)
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country) {
        return formik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country) {
        return formik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">
            Update Nomination
          </PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Account Name"
                  type="text"
                  name="account"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  disabled={true}
                  id="account"
                  value={formik.values.account}
                  error={
                    formik.touched.account &&
                    Boolean(formik.errors.account)
                  }
                  helperText={
                    formik.touched.account && formik.errors.account
                  }
                />
              <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Executive First Name"
                  type="text"
                  name="execFirstName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="execFirstName"
                  value={formik.values.execFirstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execFirstName &&
                    Boolean(formik.errors.execFirstName)
                  }
                  helperText={
                    formik.touched.execFirstName && formik.errors.execFirstName
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Executive Last Name"
                  type="text"
                  name="execLastName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="execLastName"
                  value={formik.values.execLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execLastName &&
                    Boolean(formik.errors.execLastName)
                  }
                  helperText={
                    formik.touched.execLastName && formik.errors.execLastName
                  }
                />
              </div>
            </div>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Geography *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="geography"
                defaultValue={formik?.values?.geography}
                value={formik.values.geography}
                label="Geography"
                onChange={formik.handleChange}
              >
                {Object.keys(continents).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}-{continents[item]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formik.touched.geography && formik.errors.geography}
              </FormHelperText>
            </FormControl>
<MyTextInput
                 
                 label="Department"
                 type="text"
                 name="department"
                 required={true}
                 fullWidth={true}
                 margin="normal"
                 id="department"
                 value={formik.values.department}
                 onChange={formik.handleChange}
                 tabIndex={1}
                 error={
                   formik.touched.department &&
                   Boolean(formik.errors.department)
                 }
                 helperText={
                   formik.touched.department && formik.errors.department
                 }
               />
                
<div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                   
                    <MyTextInput
                     label="Dialing Code"
                     type="number"
                     name="execDialingCode"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="execDialingCode"
                     value={formik.values.execDialingCode}
                    
                     disabled
                     error={
                       formik.touched.execDialingCode &&
                       Boolean(formik.errors.execDialingCode)
                     }
                     helperText={
                       formik.touched.execDialingCode && formik.errors.execDialingCode
                     }
                    />
                   
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                     label="Executive Mobile No."
                     type="number"
                     name="execMobileNo"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="execMobileNo"
                     value={formik.values.execMobileNo}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.execMobileNo &&
                       Boolean(formik.errors.execMobileNo)
                     }
                     helperText={
                       formik.touched.execMobileNo &&
                       formik.errors.execMobileNo
                     }
                    />
                  </div>
                </div>

               
                <MyTextInput
                     label="Manager Email"
                     type="text"
                     name="managerEmail"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="managerEmail"
                     value={formik.values.managerEmail}
                     onChange={formik.handleChange}
                     error={
                       formik.touched.managerEmail && Boolean(formik.errors.managerEmail)
                     }
                     helperText={
                       formik.touched.managerEmail && formik.errors.managerEmail
                     }
                    />
                 <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Nomination Mode *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="nominationMode"
                defaultValue={formik?.values?.nominationMode}
                value={formik.values.nominationMode}
                label="Nomination Mode"
                onChange={formik.handleChange}
              >
                {nominationModeData.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.mode}>
                        {option.mode}
                      </MenuItem>
                    );
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formik.touched.nominationMode && formik.errors.nominationMode}
              </FormHelperText>
            </FormControl>
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Status *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={formik.values.status}
                  error={
                    formik.touched.status &&
                    Boolean(formik.errors.status)
                  }
                  label="Status *"
                  onChange={formik.handleChange}
                >
                  {nominationStatusData.length > 0 &&
                    nominationStatusData.map((item) => (
                      <MenuItem key={item.id} value={item.nominationStatus}>
                        {item.nominationStatus}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={true} sx={{ color: "red" }}>
                  {formik.touched.status &&
                    formik.errors.status}
                </FormHelperText>
              </FormControl>
                
              
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                   label="HR Name"
                   type="text"
                   name="hrId"
                   required={true}
                   fullWidth={true}
                   margin="normal"
                   disabled={true}
                   id="hrId"
                   value={formik.values.hrId}
                   tabIndex={1}
                   error={
                     formik.touched.hrId && Boolean(formik.errors.hrId)
                   }
                   helperText={
                     formik.touched.hrId && formik.errors.hrId
                   }
                />
              <MyTextInput
                   label="Executive Email Id"
                   type="text"
                   name="execEmail"
                   required={true}
                   fullWidth={true}
                   margin="normal"
                   id="execEmail"
                   value={formik.values.execEmail}
                   onChange={formik.handleChange}
                   tabIndex={1}
                   error={
                     formik.touched.execEmail && Boolean(formik.errors.execEmail)
                   }
                   helperText={
                     formik.touched.execEmail && formik.errors.execEmail
                   }
                />
                 <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="country"
                defaultValue={formik?.values?.country}
                value={formik.values.country}
                label="Country"
                onChange={formik.handleChange}
              >
                {options.map((option) => {
                  if (formik.values.geography === option.continent) {
                    return (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </FormControl>
                   <MyTextInput
                 
                 label="Designation"
                 type="text"
                 name="designation"
                 required={true}
                 fullWidth={true}
                 margin="normal"
                 id="designation"
                 value={formik.values.designation}
                 onChange={formik.handleChange}
                 tabIndex={1}
                 error={
                   formik.touched.designation &&
                   Boolean(formik.errors.designation)
                 }
                 helperText={
                   formik.touched.designation && formik.errors.designation
                 }
               />
               <div style={{ display: "flex" }}>
              <div style={{width: '35%', paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Manager First Name"
                  type="text"
                  name="managerFirstName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="managerFirstName"
                  value={formik.values.managerFirstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerFirstName &&
                    Boolean(formik.errors.managerFirstName)
                  }
                  helperText={
                    formik.touched.managerFirstName && formik.errors.managerFirstName
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Manager Last Name"
                  type="text"
                  name="managerLastName"
                  required={true}
                  margin="normal"
                  fullWidth={true}
                  id="managerLastName"
                  value={formik.values.managerLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerLastName &&
                    Boolean(formik.errors.managerLastName)
                  }
                  helperText={
                    formik.touched.managerLastName && formik.errors.managerLastName
                  }
                />
              </div>
            </div>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                  <MyTextInput
                        
                     label="Dialing Code"
                     type="number"
                     name="managerDialingCode"
                     required={true}
                     fullWidth={true}
                     margin="normal"
                     id="managerDialingCode"
                     value={formik.values.managerDialingCode}
                    
                     disabled
                     error={
                       formik.touched.managerDialingCode &&
                       Boolean(formik.errors.managerDialingCode)
                     }
                     helperText={
                       formik.touched.managerDialingCode && formik.errors.managerDialingCode
                     }
                      
                      />
                  </div>

                  <div style={{ width: "65%" }}>
                  <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={formik.values.managerContactNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.managerContactNo &&
                        Boolean(formik.errors.managerContactNo)
                      }
                      helperText={
                        formik.touched.managerContactNo &&
                        formik.errors.managerContactNo
                      }
                    />
                   
                  </div>
                </div>


             
                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                 
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                 
                />

            
                </Grid>
              <Box textAlign="center" width="100%">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                  >
                  Submit
                </FormSubmitButton>
              </Box>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default UpdateNomination;
