import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countries, continents } from "countries-list";
import { useLocation } from "react-router-dom";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormTextField as MyTextInput,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  ConfirmDialog,
  ModalCloseBtn,
} from "../../../components";
// redux
import {
  resendEmail,
  createMentor,
  updateMentor,
  deleteMentor,
  rowSelect,
  getMentor,
  resetMessage,
  resetErrMessage,
} from "../../../features/mentor/mentorSlice";

// ---------------------------------------------------------------------------

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.object().required("Geo Name is required"),
  country: Yup.object().required("Country is required "),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  email: Yup.string("Enter your email").required("Email is required"),
  comments: Yup.string().nullable(),
});

const editValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  geography: Yup.string().required("Geo Name is required"),
  country: Yup.string().required("Country is required "),
  dialingCode: Yup.string().required("Dialing Code is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  email: Yup.string("Enter your email").required("Email is required"),
  comments: Yup.string().nullable(),
});

// -------------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// ---------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },
  filter_grid : {
    [theme.breakpoints.down('md')]: {
      display:'flex !important',
      flexDirection:'column !important'
  
    },
  },
  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))

const Onboarding = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
const [countryArr, setCountryArr] = useState([])

  const location = useLocation()
  const dispatch = useDispatch();
  const { message, errMessage, mentorsData, selectedData } =
    useSelector((state) => ({
      ...state.mentor,
    }));

  function getFullName(params) {
    return <GridCellExpand value={`${params.row.firstName || ""} ${params.row.lastName || ""}`} />
  }

  function getEmail(params) {
    return params.row.excoMentor.email;
  }

  function getContactNumber(params) {
    return `${params?.row?.dialingCode}-${params?.row?.contactNumber}`;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }  

  const data = {
    rows: mentorsData,
    columns: [
      {
        field: "mentorName",
        headerName: "Mentor Name",
        width: 200,
        renderCell: getFullName,
        valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
      },
      {
        field: "email",
        headerName: "ExCo Login/Email",
        width: 250,
        renderCell: (params) => <GridCellExpand value={params.row.excoMentor.email || ''}  />,
        valueGetter: getEmail,
      },
      {
        field: "geoName",
        headerName: "Geography",
        width: 150,
      },
      {
        field: "country",
        headerName: "Country",
        width: 150,
      },
      {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 150,
        valueGetter: getContactNumber,
      },
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand
      },
      {
        field:'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 150,
        renderCell: (params) => {
          return (
          !params?.row?.excoMentorActivity?.isActive &&  <FormSubmitButton type="submit" variant="contained" sx={{textTransform : 'none'}} onClick={() => {
            let data={id: params?.row?.excoMentor?.id} 
          dispatch(resendEmail(data))}}> Re-Send Mail</FormSubmitButton>
          );
        },
      },
    ],
  };

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        firstName: "",
        lastName: "",
        email: "",
        geography: "",
        country: "",
        dialingCode: "",
        contactNumber: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure to delete this record?",
              subTitle: "You can't undo this operation",
              onConfirm: () => handleDeleteMentor(),
            })

  const handleDeleteMentor = () => {
    let data = {
      id: selectedData?.excoMentor?.id,
    };
    dispatch(deleteMentor(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getMentor());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      geography: "",
      country: "",
      dialingCode: "",
      contactNumber: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createMentor({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          geoName: values.geography?.code,
          country: values.country?.name,
          dialingCode: values.dialingCode,
          contactNumber: values.contactNumber.toString(),
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getMentor());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedData?.firstName,
      lastName: selectedData?.lastName,
      email: selectedData?.excoMentor?.email,
      geography: selectedData?.geoName,
      country: selectedData?.country,
      dialingCode: selectedData?.dialingCode,
      contactNumber: selectedData?.contactNumber,
      comments: selectedData?.comments,
    },

    validationSchema: editValidationSchema,
    onSubmit: (values) => {
      dispatch(
        updateMentor({
          id: selectedData?.excoMentor?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          geoName: values.geography,
          country: values.country,
          dialingCode: values.dialingCode,
          contactNumber: values.contactNumber.toString(),
          comments: values?.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeEditModal();
            dispatch(getMentor());
          }, 2000);
        }
        if (res?.payload?.error || res?.payload?.warn) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getMentor());
  }, []);

  useEffect(() => {
    let countries = []
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option)
      }
    });
    setCountryArr(countries)
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formikEdit?.values?.country) {
        return formikEdit?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formikEdit?.values?.country]);


  const classes = useStyles();
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader"> Mentor Management</PageHeader>
        <MuiDataGrid
          setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
          setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
          setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }
          toolbarColumn = {true}
          toolbarFilter = {true}
          toolbarDensity = {true}
          toolbarExport = {true}
          data={data}
          select={(data) => dispatch(rowSelect(data))}
        />
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />

          <div className={classes.responsiveText}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "49%",paddingRight: "0.5rem"  }}>
                <MyTextInput
                  label=" Mentor First Name"
                  type="text"
                  name="firstName"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
              <div style={{ width: "49%", }}>
                <MyTextInput
                  label="Mentor Last Name"
                  type="text"
                  name="lastName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
            </div>
            <MyTextInput
              label="Email"
              type="email"
              name="email"
              required={true}
              fullWidth={true}
              margin="normal"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
<MuiAutocomplete
              name="geography"
              options={geographyArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.geography}
              optionValue="code"
              optionSecValue="name"
              label="Geography"
              required={true}
              error={
                formik.touched.geography && Boolean(formik.errors.geography)
              }
              helperText={
                formik.touched.geography && formik.errors.geography
              }
            />
<MuiAutocomplete
              name="country"
              options={countryArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.country}
              optionValue="name"
              label="Country"
              required={true}
              error={
                formik.touched.country && Boolean(formik.errors.country)
              }
              helperText={
                formik.touched.country && formik.errors.country
              }
            />
            <div style={{ display: "flex" }}>
              <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Dialing Code"
                  type="number"
                  name="dialingCode"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dialingCode"
                  value={formik.values.dialingCode}
                  disabled
                  error={
                    formik.touched.dialingCode &&
                    Boolean(formik.errors.dialingCode)
                  }
                  helperText={
                    formik.touched.dialingCode && formik.errors.dialingCode
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Contact Number"
                  type="number"
                  name="contactNumber"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactNumber"
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactNumber &&
                    Boolean(formik.errors.contactNumber)
                  }
                  helperText={
                    formik.touched.contactNumber && formik.errors.contactNumber
                  }
                />
              </div>
            </div>
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </div>
          <FormSubmitButton
            type="submit"
            variant="contained"
          >
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />

          <div className={classes.responsiveText}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "49%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label=" Mentor First Name"
                  type="text"
                  name="firstName"
                  fullWidth={true}
                  required={true}
                  margin="normal"
                  id="firstName"
                  value={formikEdit.values.firstName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.firstName &&
                    Boolean(formikEdit.errors.firstName)
                  }
                  helperText={
                    formikEdit.touched.firstName && formikEdit.errors.firstName
                  }
                />
              </div>
              <div style={{ width: "49%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Mentor Last Name"
                  type="text"
                  name="lastName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="lastName"
                  value={formikEdit.values.lastName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.lastName &&
                    Boolean(formikEdit.errors.lastName)
                  }
                  helperText={
                    formikEdit.touched.lastName && formikEdit.errors.lastName
                  }
                />
              </div>
            </div>
            <MyTextInput
              label="Email"
              type="email"
              name="email"
              required={true}
              fullWidth={true}
              margin="normal"
              id="email"
              value={formikEdit.values.email}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.email && Boolean(formikEdit.errors.email)
              }
              helperText={formikEdit.touched.email && formikEdit.errors.email}
            />
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Geography *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="geography"
                defaultValue={formikEdit?.values?.geography}
                value={formikEdit.values.geography}
                label="Geography"
                onChange={formikEdit.handleChange}
              >
                {Object.keys(continents).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}-{continents[item]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.geography && formikEdit.errors.geography}
              </FormHelperText>
            </FormControl>

            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="country"
                defaultValue={formikEdit?.values?.country}
                value={formikEdit.values.country}
                label="Country"
                onChange={formikEdit.handleChange}
              >
                {options.map((option) => {
                  if (formikEdit.values.geography === option.continent) {
                    return (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.country && formikEdit.errors.country}
              </FormHelperText>
            </FormControl>

            <div style={{ display: "flex" }}>
              <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Dialing Code"
                  type="number"
                  name="dialingCode"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dialingCode"
                  value={formikEdit.values.dialingCode}
                  disabled
                  error={
                    formikEdit.touched.dialingCode &&
                    Boolean(formikEdit.errors.dialingCode)
                  }
                  helperText={
                    formikEdit.touched.dialingCode &&
                    formikEdit.errors.dialingCode
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Contact Number"
                  type="number"
                  name="contactNumber"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactNumber"
                  value={formikEdit.values.contactNumber}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.contactNumber &&
                    Boolean(formikEdit.errors.contactNumber)
                  }
                  helperText={
                    formikEdit.touched.contactNumber &&
                    formikEdit.errors.contactNumber
                  }
                />
              </div>
            </div>
            <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />
          </div>

          <FormSubmitButton
            type="submit"
            variant="contained"
          >
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Onboarding;
