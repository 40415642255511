import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  RESEND_CUST_USER_EMAIL,
  CREATE_CUST_USER,
  UPDATE_CUST_USER,
  GET_CUST_USER,
  DELETE_CUST_USER,
  GET_CUST_CLIENTS,
  GET_CUST_HR,
  GET_CUST_MANAGER,
  GET_CLIENT_BY_CUST,
  GET_ALL_CLIENT_BY_ACC,
  GET_CUST_USER_NOT_MAPPED,
  GET_CUST_USER_WITHOUT_OPP,
  GET_CUST_USER_ROLES,
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const resendEmail = createAsyncThunk(
  "custUser/resendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(RESEND_CUST_USER_EMAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCustUser = createAsyncThunk(
  "custUser/createCustUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_CUST_USER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustUser = createAsyncThunk(
  "custUser/getCustUser",
  async () => {
    return axios.get(GET_CUST_USER, { withCredentials: true });
  }
);

export const getAllClients = createAsyncThunk(
  "custUser/getAllClients",
  async () => {
    return axios.get(GET_CUST_CLIENTS, { withCredentials: true });
  }
);

export const getAllCustHr = createAsyncThunk(
  "custUser/getAllCustHr",
  async () => {
    return axios.get(GET_CUST_HR, { withCredentials: true });
  }
);

export const getAllCustManager = createAsyncThunk(
  "custUser/getAllCustManager",
  async () => {
    return axios.get(GET_CUST_MANAGER, { withCredentials: true });
  }
);

export const getCustHrByAcc = createAsyncThunk(
  "custUser/getCustHrByAcc",
  async (id) => {
    return axios.get(`${GET_CUST_HR}/${id}`, { withCredentials: true });
  }
);

export const getCustManagerByAcc = createAsyncThunk(
  "custUser/getCustManagerByAcc",
  async (id) => {
    return axios.get(`${GET_CUST_MANAGER}/${id}`, { withCredentials: true });
  }
);

export const getClientByCust = createAsyncThunk(
  "custUser/getClientByCust",
  async (id) => {
    return axios.get(`${GET_CLIENT_BY_CUST}/${id}`, { withCredentials: true });
  }
);

export const getCustUserNotMapped = createAsyncThunk(
  "custUser/getCustUserNotMapped",
  async () => {
    return axios.get(GET_CUST_USER_NOT_MAPPED, { withCredentials: true });
  }
);

export const getCustUserWithoutOpp = createAsyncThunk(
  "custUser/getCustUserWithoutOpp",
  async () => {
    return axios.get(GET_CUST_USER_WITHOUT_OPP, { withCredentials: true });
  }
);

export const getAllClientByAcc = createAsyncThunk(
  "custUser/getAllClientByAcc",
  async (id) => {
    return axios.get(`${GET_ALL_CLIENT_BY_ACC}/${id}`, {
      withCredentials: true,
    });
  }
);

export const updateCustUser = createAsyncThunk(
  "custUser/updateCustUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_CUST_USER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustUser = createAsyncThunk(
  "custUser/deleteCustUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_CUST_USER, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCustUserRoles = createAsyncThunk(
  "custUser/getCustUserRoles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_CUST_USER_ROLES, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const custUserSlice = createSlice({
  name: "custUser",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    custUserData: [],
    custClientsData: [],
    custHrData: [],
    custManagerData: [],
    clientByCustData: [],
    clientByAccData: [],
    custUserNotMappedData: [],
    custUserWithoutOppData: [],
    custUserRolesData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [resendEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [resendEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [resendEmail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [createCustUser.pending]: (state, action) => {
      state.loading = true;
    },
    [createCustUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createCustUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCustUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.custUserData = action?.payload?.data;
    },
    [getCustUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllClients.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.custClientsData = action?.payload?.data;
    },
    [getAllClients.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllCustHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllCustHr.fulfilled]: (state, action) => {
      state.loading = false;
      state.custHrData = action?.payload?.data;
    },
    [getAllCustHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllCustManager.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllCustManager.fulfilled]: (state, action) => {
      state.loading = false;
      state.custManagerData = action?.payload?.data;
    },
    [getAllCustManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCustHrByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustHrByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.custHrData = action?.payload?.data;
    },
    [getCustHrByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCustManagerByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustManagerByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.custManagerData = action?.payload?.data;
    },
    [getCustManagerByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getClientByCust.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientByCust.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientByCustData = action?.payload?.data;
    },
    [getClientByCust.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCustUserNotMapped.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustUserNotMapped.fulfilled]: (state, action) => {
      state.loading = false;
      state.custUserNotMappedData = action?.payload?.data;
    },
    [getCustUserNotMapped.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCustUserWithoutOpp.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustUserWithoutOpp.fulfilled]: (state, action) => {
      state.loading = false;
      state.custUserWithoutOppData = action?.payload?.data;
    },
    [getCustUserWithoutOpp.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllClientByAcc.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllClientByAcc.fulfilled]: (state, action) => {
      state.loading = false;
      state.clientByAccData = action?.payload?.data;
    },
    [getAllClientByAcc.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateCustUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCustUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCustUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteCustUser.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCustUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteCustUser.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getCustUserRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getCustUserRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.custUserRolesData = action?.payload?.data;
    },
    [getCustUserRoles.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  custUserSlice.actions;
export default custUserSlice.reducer;
