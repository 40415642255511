import React, { useState, useEffect } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import { countries, continents } from "countries-list";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// material ui
import { makeStyles } from "@mui/styles";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
  Grid,
  Autocomplete,
  TextField
} from "@mui/material";
// components
import Layout from "../../../layout/Layout";
import {
  MuiDataGrid,
  MuiAutocomplete,
  geographyArr,
  GridCellExpand,
  ModalPopUp,
  FormTextField as MyTextInput,
  FormSubmitButton,
  Notifier,
  ErrorNotifier,
  ModalCloseBtn,
  ConfirmDialog,
} from "../../../components";
// redux
import {
  resendEmail,
  createAccount,
  updateAccount,
  deleteAccount,
  selectRow,
  getAccount,
  resetErrMessage,
  resetMessage,
} from "../../../features/auth/authSlice";
import {getUserGroups} from '../../../features/platform/role_management/userGroupsSlice'
import { getRoles } from "../../../features/platform/role_management/rolesSlice";

// ---------------------------------------------------------------------------------

const validationSchema = Yup.object({
  firstName: Yup.string("Enter your First Name").required(
    "First Name is required"
  ),
  lastName: Yup.string("Enter your Last Name").required(
    "Last Name is required"
  ),
  geography: Yup.object().required("Geo Name is required"),
  country: Yup.object().required("Country is required "),
  userGroup: Yup.array().required("User Group is required"),
  primaryRole: Yup.object().required("Primary Role is required"),
  secondaryRoles: Yup.array().required("Secondary Role is required"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  dialingCode: Yup.string().required("DialingCode is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  comments: Yup.string().nullable(),
});

const editValidationSchema = Yup.object({
  firstName: Yup.string("Enter your First Name").required(
    "First Name is required"
  ),
  lastName: Yup.string("Enter your Last Name").required(
    "Last Name is required"
  ),
  geography: Yup.string().required("Geo Name is required"),
  country: Yup.string().required("country is required "),
  userGroup: Yup.array().required("User Group is required"),
  primaryRole: Yup.object("Enter your Primary Role").required("Primary Role is required"),
  secondaryRoles: Yup.array("Enter your Secondary Roles").required("Secondary Roles is required"),
  email: Yup.string("Enter your email")
    .required("Email is required")
    // .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "email is not valid"),
    .email("Email is not valid"),
  dialingCode: Yup.string().required("DialingCode is required"),
  contactNumber: Yup.string()
    .required("Contact Number is required ")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  comments: Yup.string().nullable(),
});

// ----------------------------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

// -------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'50rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },
  filter_grid : {
    [theme.breakpoints.down('md')]: {
      display:'flex !important',
      flexDirection:'column !important'
  
    },
  },
  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))


const Onboarding = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
const [countryArr, setCountryArr] = useState([])
const [rolesArr, setRolesArr] = useState([]) 
const [secondaryRolesArr, setSecondaryRolesArr] = useState([])

  const { message, errMessage, accountsData, selectedData } =
    useSelector((state) => ({
      ...state.auth,
    }));
  const {userGroupsData} = useSelector((state) => ({...state.userGroups}));
  const {rolesData} = useSelector((state) => ({...state.roles}))

  const location = useLocation()
  const dispatch = useDispatch();

  function getFullName(params) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
  }

  function getUserGroup(params) {
    return <GridCellExpand value={[...new Map(params.row.excoUserRole.map(item => [item.userGroup['id'], item.userGroup])).values()].map(item => `${item?.groupName}, `) || ""} width={params.colDef.computedWidth} />;
  }

  function getPrimaryRole(params) {
    return <GridCellExpand value={params.row.excoUserRole.map(item => item.isPrimary && item?.userRole?.roleName ) || ""} width={params.colDef.computedWidth} /> ;
  }

  function getSecondaryRoles(params) {
    return <GridCellExpand value={params.row.excoUserRole.map(item => !item.isPrimary && `${item?.userRole?.roleName}, `) || ""} width={params.colDef.computedWidth} />;
  }

  function getContactNumber(params) {
    return `${params?.row?.dialingCode}-${params?.row?.contactNumber}`;
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }  

  const data = {
    rows: accountsData,
    columns: [
      {
        field: "firstName",
        headerName: "User Name",
        width: 200,
        valueGetter: getFullName,
      },
      {field: "userGroup", headerName: "User Group", width: 150, renderCell: getUserGroup, valueGetter: (params) => params.row.excoUserRole.map(item => item?.userGroup?.groupName)},
      { field: "primaryRole", headerName: "Primary Role", width: 150, renderCell: getPrimaryRole, valueGetter: (params) => params.row.excoUserRole.map(item => item.isPrimary && item?.userRole?.roleName ) },
      { field: "secondaryRole", headerName: "Secondary Roles", width: 150, renderCell: getSecondaryRoles, valueGetter: (params) => params.row.excoUserRole.map(item => !item.isPrimary && item?.userRole?.roleName) },
      {
        field: "email",
        headerName: "ExCo Id/Email",
        width: 250,
        renderCell: (params) => <GridCellExpand value={params.row.excoUser.email || ''}  />,
        valueGetter: (params) => params.row.excoUser.email
      },
      { field: "geoName", headerName: "Geography", width: 150 },
      { field: "country", headerName: "Country", width: 150 },
      {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 150,
        valueGetter: getContactNumber,
      },
      { field: "comments", headerName: "Comments", width: 250, renderCell: renderCellExpand },
      {
        field:'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 150,
        renderCell: (params) => {
          return (
          !params?.row?.excoUserActivity?.isActive &&  <FormSubmitButton type="submit" variant="contained" sx={{textTransform : 'none'}} onClick={() => {
            let data = {id : params?.row?.excoUser?.id}
            dispatch(resendEmail(data)).then((res) => {
              if (res?.payload?.data?.ok) {
                setTimeout(() => {
                  dispatch(resetMessage());
                }, 2000);
              }
              if (res?.payload?.error) {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                }, 3000);
              }
            })
          }}> Re-Send Mail</FormSubmitButton>
          );
        },
      },
    ],
  };

  const showAddModel = () => {
    setShowAdd(!showAdd);
  };

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        firstName: "",
        lastName: "",
        email: "",
        userGroup: [],
        primaryRole: "",
        secondaryRoles: [],
        geography: "",
        country: "",
        dialingCode: "",
        contactNumber: "",
        comments: "",
      },
    });
  };

  const showEditModal = () => {
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
  };

  const showDelete = () =>
  setConfirmDialog({
    isOpen: true,
    title: "Are you sure to delete this record?",
    subTitle: "You can't undo this operation",
    onConfirm: () => handleDeleteAccount(),
  })

  const handleDeleteAccount = () => {
    let data = {
      id: selectedData?.excoUser?.id,
    };
    dispatch(deleteAccount(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getAccount());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userGroup: [],
      primaryRole: "",
      secondaryRoles: [],
      geography: "",
      country: "",
      dialingCode: "",
      contactNumber: "",
      comments: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      let data = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        userGroupId: values?.userGroup.map(item => item.id),
        primaryUserRoleId: values?.primaryRole?.id,
        secondaryUserRoleId: values?.secondaryRoles.map(item => item.id),
        geoName: values?.geography?.code,
        country: values?.country?.name,
        dialingCode: values?.dialingCode,
        contactNumber: values?.contactNumber.toString(),
        comments: values?.comments,
      };
      dispatch(createAccount(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(getAccount());
            showAddModel();
            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            closeModel();
          }, 3000);
        }
      });
    },
  });

  const formikEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedData?.firstName,
      lastName: selectedData?.lastName,
      email: selectedData?.excoUser?.email,
      userGroup: typeof selectedData?.excoUserRole !== "undefined" && selectedData?.excoUserRole?.length > 0 ? [...new Map(selectedData.excoUserRole.map(item => [item.userGroup['id'], item.userGroup])).values()].map(item => item) : [],
      primaryRole: typeof selectedData?.excoUserRole !== "undefined" && selectedData?.excoUserRole?.length > 0 ? selectedData.excoUserRole?.filter(item => item.isPrimary === true).map(value => value.userRole)[0] : "",
      secondaryRoles: typeof selectedData?.excoUserRole !== "undefined" && selectedData?.excoUserRole?.length > 0 ? selectedData.excoUserRole?.filter(item => item.isPrimary === false).map(value => value?.userRole) : "",
      geography: selectedData?.geoName,
      country: selectedData?.country,
      dialingCode: selectedData?.dialingCode,
      contactNumber: selectedData?.contactNumber,
      comments: selectedData?.comments,
    },

    // validationSchema: editValidationSchema,
    onSubmit: (values) => {
      
      let data = {
        id: selectedData?.excoUser?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        userGroupId: values?.userGroup.map(item => item.id),
        primaryUserRoleId: values?.primaryRole?.id,
        secondaryUserRoleId: values?.secondaryRoles.map(item => item.id),
        geoName: values?.geography,
        country: values?.country,
        dialingCode: values?.dialingCode,
        contactNumber: values?.contactNumber.toString(),
        comments: values?.comments,
      };
      dispatch(updateAccount(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            dispatch(getAccount());
            closeEditModal();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            closeEditModal();
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);

  useEffect(() => {
    dispatch(getAccount());
    dispatch(getUserGroups())
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    let countries = []
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option)
      }
      setCountryArr(countries)
    });
  }, [formik?.values?.geography]);

  useEffect(() => {
    let roles = []
    rolesData.length > 0 && rolesData.map((option) => {
      formik?.values?.userGroup.map(item => {
        if (option?.userGroup?.id === item.id) {
          return roles.push(option)
        }
      })
      
      setRolesArr(roles)
    });
  }, [formik?.values?.userGroup]);

  useEffect(() => {
    let roles = []
    rolesData.length > 0 && rolesData.map((option) => {
      formikEdit?.values?.userGroup.length > 0 && formikEdit?.values?.userGroup.map(item => {
        if (option?.userGroup?.id === item.id) {
          return roles.push(option)
        }
      })
      
      setRolesArr(roles)
    });
  }, [formikEdit?.values?.userGroup]);

  useEffect(() => {
    let secondaryRoles = []
    rolesData.length > 0 && rolesData.map((option) => {
      formik?.values?.userGroup.map(item => {
        if(option?.userGroup?.id === item.id) {
          if(formik.values.primaryRole) {

            if ( option?.id !== formik.values.primaryRole.id) {
              return secondaryRoles.push(option) 
            }
          } else {
            return formik.setFieldValue("secondaryRoles", [])
          }
        }
      })
      setSecondaryRolesArr(secondaryRoles)
    });
  }, [formik?.values?.primaryRole, formik?.values?.userGroup]);

  useEffect(() => {
    let secondaryRoles = []
    
    rolesData.length > 0 && rolesData.map((option) => {
      formikEdit?.values?.userGroup.length > 0 && formikEdit?.values?.userGroup.map(item => {
        if(option?.userGroup?.id === item.id) {            
            if ( option?.id !== formikEdit.values.primaryRole?.id) {
              return secondaryRoles.push(option)
            } 
        }
      })
      setSecondaryRolesArr(secondaryRoles)
    });
    showEdit && formikEdit.setFieldValue("secondaryRoles", [])
  }, [formikEdit?.values?.primaryRole, formikEdit?.values?.userGroup]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("dialingCode", option?.phone);
      }
      if (option.name === formikEdit?.values?.country) {
        return formikEdit?.setFieldValue("dialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country, formikEdit?.values?.country]);

  useEffect(() => {
    if(formik.values.secondaryRoles.length > 0) {
      return formik.setFieldValue("secondaryRoles", [])
    }
  }, [formik.values.primaryRole, formikEdit.values.primaryRole])
  
  useEffect(() => {
    if(showEdit){
      setSecondaryRolesArr(formikEdit.values.secondaryRoles)
    }
  },[showEdit])
  const classes = useStyles();
  return (
    <Layout>
      <>
        <PageHeader variant="pageHeader"> User Onboarding</PageHeader>
        <MuiDataGrid
         setShowAdd={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && showAddModel}
         setShowEdit={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && showEditModal}
         setShowDelete={location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "delete") && showDelete
          }
          toolbarColumn = {true}
          toolbarFilter = {true}
          toolbarDensity = {true}
          toolbarExport = {true}
          data={data}
          select={(data) => dispatch(selectRow(data))}
        />
      </>

      <ModalPopUp
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div className={classes.responsiveText}>
          <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
            <div style={{ display: "flex" }}>
              <div style={{ paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="First Name"
                  type="text"
                  name="firstName"
                  required={true}
                  margin="normal"
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
              <div>
                <MyTextInput
                  label="Last Name"
                  type="text"
                  name="lastName"
                  required={true}
                  margin="normal"
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
            </div>
            <MuiAutocomplete
              name="geography"
              options={geographyArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.geography}
              optionValue="code"
              optionSecValue="name"
              label="Geography"
              required={true}
              error={formik.touched.geography && Boolean(formik.errors.geography)}
              helperText={formik.touched.geography && formik.errors.geography}
            />
            <Autocomplete
                name="userGroup"
        multiple
        id="tags-outlined"
        options={userGroupsData}
        getOptionLabel={(option) => option.groupName}
        value={formik.values.userGroup}
        onChange={(event, newValue) => formik.setFieldValue("userGroup", [...newValue])}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="User Group"
            required={true}
            error={formik.touched.userGroup && Boolean(formik.errors.userGroup)}
            helperText={formik.touched.userGroup && formik.errors.userGroup}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={userGroupsData.length === 0}
      />
       <Autocomplete
                name="secondaryRoles"
                multiple
        id="tags-outlined"
        options={secondaryRolesArr?.length > 0 && secondaryRolesArr}
        getOptionLabel={(option) => Object.keys(option).length > 0 ? `${option["roleCode"]}-${option["roleName"]}`: ""}
        value={formik.values.secondaryRoles}
        onChange={(event, newValue) => formik.setFieldValue("secondaryRoles", newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Secondary Roles"
            error={formik.touched.secondaryRoles && Boolean(formik.errors.secondaryRoles)}
            helperText={formik.touched.secondaryRoles && formik.errors.secondaryRoles}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={rolesArr.length === 0 || secondaryRolesArr.length === 0}
      />
           <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <MyTextInput
              label="Email"
              type="email"
              name="email"
              required={true}
              fullWidth={true}
              margin="normal"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <MuiAutocomplete
              name="country"
              options={countryArr}
              setValue={(name, newValue) =>
                formik.setFieldValue(name, newValue)
              }
              value={formik.values.country}
              optionValue="name"
              label="Country"
              required={true}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
             <Autocomplete
                name="primaryRole"
        id="tags-outlined"
        options={rolesArr?.length > 0 && rolesArr}
        getOptionLabel={(option) => Object.keys(option).length > 0 ? `${option["roleCode"]}-${option["roleName"]}` : ""}
        value={formik.values.primaryRole}
        onChange={(event, newValue) => formik.setFieldValue("primaryRole", newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Primary Role"
            required={true}
            error={formik.touched.primaryRole && Boolean(formik.errors.primaryRole)}
            helperText={formik.touched.primaryRole && formik.errors.primaryRole}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={rolesArr.length === 0}
      />
             <div style={{ display: "flex" }}>
              <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Dialing Code"
                  type="number"
                  name="dialingCode"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dialingCode"
                  value={formik.values.dialingCode}
                  disabled
                  error={
                    formik.touched.dialingCode &&
                    Boolean(formik.errors.dialingCode)
                  }
                  helperText={
                    formik.touched.dialingCode && formik.errors.dialingCode
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Contact Number"
                  type="number"
                  name="contactNumber"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactNumber"
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  tabIndex={10}
                  error={
                    formik.touched.contactNumber &&
                    Boolean(formik.errors.contactNumber)
                  }
                  helperText={
                    formik.touched.contactNumber && formik.errors.contactNumber
                  }
                />
              </div>
            </div>
            
            </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ModalPopUp
        open={showEdit}
        handleClose={closeEditModal}
        closeModel={closeEditModal}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          noValidate
          onSubmit={formikEdit.handleSubmit}
        >
          <ModalCloseBtn close={closeEditModal} />
          <div className={classes.responsiveText}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
            
            <div style={{ display: "flex" }}>
              <div style={{ paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="First Name"
                  type="text"
                  name="firstName"
                  required={true}
                  margin="normal"
                  id="firstName"
                  value={formikEdit.values.firstName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.firstName &&
                    Boolean(formikEdit.errors.firstName)
                  }
                  helperText={
                    formikEdit.touched.firstName && formikEdit.errors.firstName
                  }
                />
              </div>
              <div>
                <MyTextInput
                  label="Last Name"
                  type="text"
                  name="lastName"
                  required={true}
                  margin="normal"
                  id="lastName"
                  value={formikEdit.values.lastName}
                  onChange={formikEdit.handleChange}
                  error={
                    formikEdit.touched.lastName &&
                    Boolean(formikEdit.errors.lastName)
                  }
                  helperText={
                    formikEdit.touched.lastName && formikEdit.errors.lastName
                  }
                />
              </div>
            </div>
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Geography *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="geography"
                defaultValue={formikEdit?.values?.geography}
                value={formikEdit.values.geography}
                label="Geography"
                onChange={formikEdit.handleChange}
              >
                {Object.keys(continents).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}-{continents[item]}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.geography && formikEdit.errors.geography}
              </FormHelperText>
            </FormControl>
            <Autocomplete
                name="userGroup"
        multiple
        id="tags-outlined"
        options={userGroupsData.length > 0 && userGroupsData}
        getOptionLabel={(option) => option.groupName}
        value={formikEdit.values.userGroup}
        onChange={(event, newValue) => formikEdit.setFieldValue("userGroup", [...newValue])}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="User Group"
            required={true}
            error={formikEdit.touched.userGroup && Boolean(formikEdit.errors.userGroup)}
            helperText={formikEdit.touched.userGroup && formikEdit.errors.userGroup}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={userGroupsData.length === 0}
      />
       <Autocomplete
                name="secondaryRoles"
                multiple
        id="tags-outlined"
        options={secondaryRolesArr?.length > 0 && secondaryRolesArr}
        getOptionLabel={(option) => Object.keys(option).length > 0 ? `${option["roleCode"]}-${option["roleName"]}`: ""}
        value={formikEdit.values.secondaryRoles}
        onChange={(event, newValue) => formikEdit.setFieldValue("secondaryRoles", newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Secondary Roles"
            error={formikEdit.touched.secondaryRoles && Boolean(formikEdit.errors.secondaryRoles)}
            helperText={formikEdit.touched.secondaryRoles && formikEdit.errors.secondaryRoles}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={ secondaryRolesArr.length === 0}
      />
              <MyTextInput
              label="Comments"
              type="text"
              name="comments"
              fullWidth={true}
              margin="normal"
              id="comments"
              value={formikEdit.values.comments}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.comments &&
                Boolean(formikEdit.errors.comments)
              }
              helperText={
                formikEdit.touched.comments && formikEdit.errors.comments
              }
            />  
            </Grid>
            <Grid item xs={12} sm={12} md={6}>

            
            
            <MyTextInput
              label="Email"
              type="email"
              name="email"
              required={true}
              fullWidth={true}
              margin="normal"
              id="email"
              value={formikEdit.values.email}
              onChange={formikEdit.handleChange}
              error={
                formikEdit.touched.email && Boolean(formikEdit.errors.email)
              }
              helperText={formikEdit.touched.email && formikEdit.errors.email}
            />
            <FormControl sx={{ marginTop: "1rem" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="country"
                defaultValue={formikEdit?.values?.country}
                value={formikEdit.values.country}
                label="Country"
                onChange={formikEdit.handleChange}
              >
                {options.map((option) => {
                  if (formikEdit.values.geography === option.continent) {
                    return (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              <FormHelperText error={true} sx={{ color: "red" }}>
                {formikEdit.touched.country && formikEdit.errors.country}
              </FormHelperText>
            </FormControl>
            <Autocomplete
                name="primaryRole"
        id="tags-outlined"
        options={rolesArr?.length > 0 && rolesArr}
        getOptionLabel={(option) => Object.keys(option).length > 0 ? `${option["roleCode"]}-${option["roleName"]}` : ""}
        value={formikEdit.values.primaryRole}
        onChange={(event, newValue) => formikEdit.setFieldValue("primaryRole", newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Primary Role"
            required={true}
            error={formikEdit.touched.primaryRole && Boolean(formikEdit.errors.primaryRole)}
            helperText={formikEdit.touched.primaryRole && formikEdit.errors.primaryRole}
          />
        )}
        sx={{ marginTop: "1rem" }}
        disabled={rolesArr.length === 0}
      />
            <div style={{ display: "flex" }}>
              <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                <MyTextInput
                  label="Dialing Code"
                  type="number"
                  name="dialingCode"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="dialingCode"
                  value={formikEdit.values.dialingCode}
                  disabled
                  error={
                    formikEdit.touched.dialingCode &&
                    Boolean(formikEdit.errors.dialingCode)
                  }
                  helperText={
                    formikEdit.touched.dialingCode &&
                    formikEdit.errors.dialingCode
                  }
                />
              </div>
              <div style={{ width: "65%" }}>
                <MyTextInput
                  label="Contact Number"
                  type="number"
                  name="contactNumber"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="contactNumber"
                  value={formikEdit.values.contactNumber}
                  onChange={formikEdit.handleChange}
                  tabIndex={10}
                  error={
                    formikEdit.touched.contactNumber &&
                    Boolean(formikEdit.errors.contactNumber)
                  }
                  helperText={
                    formikEdit.touched.contactNumber &&
                    formikEdit.errors.contactNumber
                  }
                />
              </div>
            </div>
            
            </Grid>
            </Grid>
          </div>

          <FormSubmitButton type="submit" variant="contained">
            Submit
          </FormSubmitButton>
        </Box>
      </ModalPopUp>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Onboarding;
