import * as React from "react";
import { Outlet, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// materail ui
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Toolbar,
  List,
  Divider,
  IconButton,
  Badge,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Fab,
  Typography,
  Avatar,
  Menu,
  MenuItem
} from "@mui/material";
// icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
// components
import logo from "../../assets/images/exco-white-logo-one.png";
import shortLogo from "../../assets/images/signin-logo.png";
import { MuiAutocomplete, MuiTooltip } from "../../components";
import { AdminMainListItems, ExecMainListItems } from "./ListItems";
import Notifier from "../../components/common/Notifier";
// redux
import {
  logout,
  verifyLogin,
  resetErrMessage,
  resetMessage,
  getCurrentUser,
  getCurrentRoles,
  changePrimaryRole
} from "../../features/auth/authSlice";
import { getNotification } from "../../features/notifications/notificationSlice";
import { ADMIN_BASE_URL, ADMIN_CUSTOM_TEXT } from "../../utils/config";

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  closeMenu : {
    [theme.breakpoints.up('md')]: {
      display:'none !important',
      position:'absolute !important'
     },
  },
  MuiFabBtn: {
    position: "absolute !important",
    top: "2rem",
    [theme.breakpoints.down('md')]: {
      display:'none !important',
     },
    left: (open) => (open ? "14rem" : "4rem"),
    background: `${theme.palette.primary.light} !important`,
    "&:hover": {
      background: `${theme.palette.primary.main} !important`,
    },
    color: "white !impartant",
    zIndex: "1500 !important",
    width: "1.563rem !important",
    height: "1.563rem !important",
    minHeight: "0.625rem !important",
    transition: (open) =>
      open
        ? theme.transitions.create(["left"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        : theme.transitions.create(["left"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
  },
  MuiToolbar: {
    paddingRight: "3rem !important",
  },
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  width: `calc(100% - ${theme.spacing(7)})`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - ${theme.spacing(10)})`,
  },
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.sidebarBg.main,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    height: "100vh",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(10),
      },
    }),
  },
}));


const BasicMenu = ({anchorEl, handleClose, open, data}) => {
  return (
    <Menu
    style={{overflow:'scroll',overscrollBehaviorX: 'contain'}}
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    >
      {data.length > 0 && data.filter(item => item.isRead === "false").map(notif => <MenuItem 
   
      onClose={handleClose}>{notif.title}</MenuItem>)}
    </Menu>
  )
}

const DashboardContent = (props) => {
  const [menuToggle,setmenuToggle] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null)
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [userPrimaryRole, setUserPrimaryRole] = React.useState({})

  const classes = useStyles(open);

  const { message, loginData, userRolesData, isLoggedIn } = useSelector((state) => ({
    ...state.auth,
  }));

  const {notificationData} = useSelector((state) => ({...state.notification}))

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseOver = (event) => {
    setAnchorE2(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseOverClose = () => {
    setAnchorE2(null)
  }

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
    setNotificationOpen(true)
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false)
  };

  const handleLogout = () => {
    dispatch(logout()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(verifyLogin())
          navigate("/");
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const popoverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popoverOpen2 = Boolean(anchorE2)
  const id2 = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getNotification())
    dispatch(verifyLogin())
    dispatch(getCurrentRoles())
    navigate("/exco/dashboard")
    setUserPrimaryRole(loginData?.data?.getExcoUser?.excoUserRole)
    return <ProfileAvatar />
  }, []);

  React.useEffect(() => {
    return isLoggedIn === false && navigate("/")
      }, [isLoggedIn])

 const ProfileAvatar = () => {
    return <Avatar src={`${ADMIN_BASE_URL}/v1/api/authentication/profile-pic`}  sx={{fontSize: 34}}/>
  }

  const changePrimaryRoleHandler = (role) => {
    setUserPrimaryRole(role)
    let data = {
      userRoleId : role?.userRole?.id
    }
    dispatch(changePrimaryRole(data)).then(res => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage())
          handleLogout()
        }, 2000)
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    })
  }
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matches2 = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  React.useEffect(() => {
    if (matches) {
      setOpen(false);
      setmenuToggle(true)
    }
    else if (matches2) {
      setOpen(true)
      setmenuToggle(false)
    }
  }, [matches,matches2]);

  const fullSidebar = () => {
    setmenuToggle(false)
    setOpen(true)

  }

  const clickSideMenu = () => {
   if (matches) {setmenuToggle(true)
    setOpen(false)
   }
  }
 
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar className={classes.MuiToolbar}>
          <Box sx={{ flexGrow: 1, color: '#000000', textAlign: 'center' }}>
            {ADMIN_CUSTOM_TEXT && <Typography variant="h5">{ADMIN_CUSTOM_TEXT}</Typography>}
          </Box>
          <MuiTooltip title="Notifications">
            <IconButton 
            onClick={handleNotificationClick}
            anchorEl={notificationAnchorEl}
            sx={{ marginRight: "1.5rem" }}
            >
              <Badge sx={{ fontSize: 30 }} badgeContent={notificationData.length} color="error">
                <NotificationsIcon sx={{ fontSize: 36 }} />
              </Badge>
            </IconButton>
          </MuiTooltip>
          <BasicMenu
           open={notificationOpen}
           anchorEl={notificationAnchorEl}
           handleClose={handleNotificationClose}
           data={notificationData}
         
           />
            <Box>
          <MuiTooltip title="User Profile">
              <IconButton
                sx={{
                  marginTop: "1rem",
                  paddingBottom: 0,
                  paddingTop: "0.5rem",
                }}
                aria-describedby={id}
                onClick={handleClick}
              >
                <ProfileAvatar />
              </IconButton>
              </MuiTooltip>
              <Typography
                varient="body2"
                sx={{
                  color: "black !important",
                  paddingRight: "0.5rem !important",
                }}
                aria-describedby={id}
                onMouseOver={handleMouseOver}
              >
               {loginData?.data?.getExcoUser?.excoUserDetail.firstName.charAt(0)}{loginData?.data?.getExcoUser?.excoUserDetail?.lastName.charAt(0)}, {loginData?.data?.getExcoUser?.excoUserRole?.userRole?.roleCode}
              </Typography>
              <Popover
        id={id2}
        open={popoverOpen2}
        anchorEl={anchorE2}
        onClose={handleMouseOverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {userRolesData.length > 0 && userRolesData.map(item => {
          return <List key={item.id} disablePadding>
            <ListItem sx={{paddingTop: "0 !important", paddingBottom: '0 !important'}}>
              <ListItemButton onClick={() => !item.isPrimary && changePrimaryRoleHandler(item)} sx={{padding: "0 !important"}} >
                <ListItemText sx={{textAlign: 'center'}}>{item.userRole.roleCode}</ListItemText> 
              </ListItemButton>
            </ListItem>
          </List>
        })}
      </Popover>
            </Box>
        </Toolbar>
      </AppBar>
      { !menuToggle && <Drawer variant="permanent" open={open}  >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
           <CloseIcon style={{color:'white'}} onClick={clickSideMenu} className={classes.closeMenu}/>
          <Box
            edge="start"
            aria-label="open drawer"
            sx={{
              flexGrow: 1,
              textAlign: "start",
              ...(open && { display: "none" }),
            }}
          >
            <img alt="shortLogo" src={shortLogo} width="52" height="52" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              ...(!open && { display: "none" }),
              paddingTop: "1rem",
            }}
          >
            <img src={logo} alt="nav logo" width="120" height="52" />
          </Box>
        </Toolbar>

        <Divider />
        <List component="nav">
        <AdminMainListItems show={open}  clickSideMenu={clickSideMenu}/>
        </List>
      </Drawer> }



      {menuToggle &&   <Drawer variant="permanent" open={open} > 
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
             <Box>
            <MenuIcon style={{color:'white'}} onClick={fullSidebar}/>
            
            
            
            
            
          </Box>
        </Toolbar>

        <Divider />
        {/* <List component="nav">
        <AdminMainListItems show={open} />
        </List> */}
      </Drawer> }

      <Outlet />
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <List>
            {/* <ListItem>
            <MuiAutocomplete
              name="userPrimaryRole"
              options={userRolesData}
              setValue={(name, newValue) =>
                changePrimaryRoleHandler(newValue)
              }
              value={userPrimaryRole}
              innerValue={true}
              optionValue="userRole"
              optionSecValue="roleCode"
              label="Roles"
              required={true}
              // error={formik.touched.geography && Boolean(formik.errors.geography)}
              // helperText={formik.touched.geography && formik.errors.geography}
            />
            </ListItem> */}
          <Link to="/exco/my-profile"  className={classes.MuiLinks}>
          <ListItem disablePadding>
          <ListItemButton onClick={() => handleClose()}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
              </ListItemButton>
          </ListItem>
          </Link>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {open ? (
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronLeftIcon />
        </Fab>
       ) : (
        <Fab onClick={toggleDrawer} className={classes.MuiFabBtn}>
          <ChevronRightIcon />
        </Fab>
      )} 
      {message && <Notifier message={message} />}
    </Box>
  );
}

const Sidebar = (props) => {
  return <DashboardContent {...props} />;
};

export default Sidebar;
