import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// components
import Layout from "../../../layout/Layout";
import { AddEngagement, EngagementDetails } from "../engagements";
import { MuiTooltip, MuiAutocomplete } from "../../../components";

// material ui
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Card,
  CardContent,
  Fab,
  Box,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import AddIcon from "@mui/icons-material/Add";
// redux
import {
  getEngagement,
  getEngagementByAcc,
  searchEngagement,
} from "../../../features/exec/engagement/engagementSlice";
import { getAccount } from "../../../features/customer/accountSlice";
import { getEngagementStage } from "../../../features/config/engagementStageSlice";


// ------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
  },
}));

// -------------------------------------------------------------------

const Engagement = () => {
  const [showAdd, setShowAdd] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [account, setAccount] = useState("");
  const [search, setSearch] = useState("");

  const classes = useStyles();

  const { engagementData } = useSelector((state) => ({
    ...state.engagement,
  }));
  const { accountData } = useSelector((state) => ({ ...state.account }));

  const dispatch = useDispatch();
const location = useLocation()

  useEffect(() => {
    dispatch(getEngagement());
    dispatch(getAccount());
    dispatch(getEngagementStage());
  }, []);

  useEffect(() => {
    !account && !search && dispatch(getEngagement());
  }, [account, search]);

  useEffect(() => {
    account && dispatch(getEngagementByAcc(account?.id));
  }, [account]);

  useEffect(() => {
    let data = {
      search: search,
    };
    search && dispatch(searchEngagement(data));
  }, [search]);

  return (
    <Layout>
      {!showAdd && !showDetail && (
        <>
          <Stack
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} sx={{marginTop:'1.25rem'}}>

            <PageHeader variant="pageHeader" >Engagement Kanban</PageHeader>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>

            <Box
              sx={{ m: "0 !important", mb: "0.5rem !important", width: "17rem" }}
            >
              <MuiAutocomplete
                name="account"
                options={accountData}
                setValue={(name, newValue) => setAccount(newValue)}
                value={account}
                optionValue="accountName"
                label="Customer Account Name"
              />
            </Box>
</Grid>
<Grid item xs={12} sm={12} md={4}>

            <TextField
              type="text"
              id="filled-size-small"
              name="search"
              label="Search Engagement..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="filled"
              size="small"
              sx={{ width: 300, margin: "auto", marginBottom: "0.5rem",marginTop:'1.25rem' }}
            />
</Grid>
            </Grid>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack
                  flexDirection="row"
                  justifyContent="center"
                  textAlign="center"
                  position="relative"
                >
                  {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "create") && 
                  <MuiTooltip title="Add" placement="bottom">
                    <Fab
                      aria-label="add"
                      size="small"
                      onClick={() => setShowAdd(true)}
                      color="primary"
                      sx={{ position: "absolute", top: 0, left: 0, height: '2rem', width: '2rem'}}
                    >
                      <AddIcon />
                    </Fab>
                  </MuiTooltip>
    }     
                  <PageHeader variant="pageSecHeader">New</PageHeader>
                </Stack>
                {engagementData.length > 0 &&
                  engagementData.map((item) => {
                    if (item.engagementStatus === "New") {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>
                            <PageHeader variant="pageSecHeader">
                              {item.engagementName}
                            </PageHeader>
                            <Typography variant="body2">
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.firstName
                              }{" "}
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.lastName
                              },
                              &nbsp;&nbsp;&nbsp;Tier Name : {item?.engTierName}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack flexDirection="row" justifyContent="center">
                  <PageHeader variant="pageSecHeader">In Progress</PageHeader>
                </Stack>
                {engagementData.length > 0 &&
                  engagementData.map((item) => {
                    if (
                      item.engagementStatus === "Active" ||
                      item.engagementStatus === "In Progress"
                    ) {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>
                            <PageHeader variant="pageSecHeader">
                              {item.engagementName}
                            </PageHeader>
                            <Typography variant="body2">
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.firstName
                              }{" "}
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.lastName
                              },
                              &nbsp;&nbsp;&nbsp;Tier Name : {item?.engTierName}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Paper elevation={4} className={classes.paper}>
                <Stack flexDirection="row" justifyContent="center">
                  <PageHeader variant="pageSecHeader">Closed</PageHeader>
                </Stack>
                {engagementData.length > 0 &&
                  engagementData.map((item) => {
                    if (
                      item.engagementStatus === "Hold" ||
                      item.engagementStatus === "Closed"
                    ) {
                      return (
                        <Card
                          sx={{ marginBottom: "0.5rem", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setSelectedValue(item);
                          }}
                        >
                          <CardContent>
                            <PageHeader variant="pageSecHeader">
                              {item.engagementName}
                            </PageHeader>
                            <Typography variant="body2">
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.firstName
                              }{" "}
                              {
                                item?.excoCustomerUser?.excoCustomerUserDetail
                                  ?.lastName
                              },
                              &nbsp;&nbsp;&nbsp;Tier Name : {item?.engTierName}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    }
                  })}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      {showAdd && <AddEngagement close={() => setShowAdd(false)} account={account} />}
      {showDetail && (
        <EngagementDetails
          close={() => setShowDetail(false)}
          values={selectedValue}
        />
      )}
    </Layout>
  );
};

export default Engagement;
