import React, {useState} from 'react'
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

// material ui
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Grid,
    ListItem,
    ListItemText,
  } from "@mui/material";
import { makeStyles } from "@mui/styles";

// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton, Notifier, ErrorNotifier} from "../../components";
import UpdateNomination from './UpdateNomination';
// redux 
import {
    createCustUser,
    getCustUser,
    resetMessage,
    resetErrMessage,
  } from "../../features/customer/custUserSlice";

//   -----------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    muiList: {
      padding: "0 !important",
    },
    muiListHeadText: {
      width: "3.4rem",
    },
    muiListDataText: {
      width: "3rem",
    },
    cancel_padding : {
      padding:'0!important'
    }
  }));

//   ------------------------------------------------------------

function NominationDetail(props) {
    const [showEdit, setShowEdit] = useState(false);

    const { selectedData } = useSelector((state) => ({
        ...state.nomination,
      }));

      const {
        message,
        errMessage,
      } = useSelector((state) => ({ ...state.custUser }));

      const classes = useStyles();

      const dispatch = useDispatch();

      const location = useLocation()

      const createClient = () =>  {
        console.log("function working")
        dispatch(
            createCustUser(
               {
                    accountId: selectedData.excoCustomerAccount?.id,
                    clientFirstName: selectedData.executiveName.split(" ")[0],
                    clientLastName: selectedData.executiveName.split(" ")[1],
                    geoName: selectedData.geoName,
                    country: selectedData.country,
                    designation: selectedData.designation,
                    contactEmail: selectedData.executiveEmail,
                    dialingCode: selectedData.executiveDialingCode,
                    contactNumber: selectedData.executiveMobileNo.toString(),
                    roles: ["CLIENT"],
                    hrID: selectedData.excoCustomerUser?.id,
                    comments: selectedData?.comment,
                  })).then((res) => {
                    if (res?.payload?.data?.ok) {
                      setTimeout(() => {
                        dispatch(resetMessage());
                        dispatch(getCustUser());
                      }, 2000);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 3000);
                    }
                  })
        
                }
      

  return (
    <>
    {!showEdit &&
    <Card sx={{ marginTop: "0.5rem" }}>
      <Grid container spacing={2} justifyContent="space-around"  alignItems="center">
      <Grid item xs={12} sm={12} md={5}>
          <CardHeader
          
            title={
              <PageHeader variant="pageHeader">Nomination Details</PageHeader>
            }
       
          />
          </Grid>
          <Grid item xs={10} sm={10} md={5}>
          <CardHeader
          
         
            action={<>
              {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") && <FormSubmitButton variant="contained" sx={{marginRight: '0.5rem'}} onClick={() => setShowEdit(true)}>Edit</FormSubmitButton>}
              {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "createClient") && selectedData?.status === "Ready to Engage" && <FormSubmitButton variant="contained" sx={{marginRight: '0.5rem'}} onClick={() => createClient()}>Create Client</FormSubmitButton>}
            
              </>
            }
          />
          </Grid>
         
          <Grid item xs={2} sm={2} md={2}>
          <IconButton className={classes.cancel_padding} aria-label="cancel" onClick={() => props.close()}>
                <CancelIcon color="red" />
              </IconButton>
              </Grid>
          </Grid>
         
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Account Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.excoCustomerAccount?.accountName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    HR Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.excoCustomerUser?.excoCustomerUserDetail?.firstName} &nbsp; {selectedData?.excoCustomerUser?.excoCustomerUserDetail?.lastName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Executive Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.executiveName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText style={{wordWrap:'break-word'}} className={classes.muiListDataText}>
                    {
                      selectedData?.executiveEmail
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Geography
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.geoName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Country
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.country}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Department
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.department}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Designation
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.designation}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Number
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.executiveDialingCode}-{selectedData?.executiveMobileNo}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.managerName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Email
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText style={{wordWrap:'break-word'}} className={classes.muiListDataText}>
                    {selectedData?.managerEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Manager Contact No.
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.managerDialingCode}-{selectedData?.managerMobileNo}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                   Nomination Status
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.status}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Mode of Nomination
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.modeOfNomination}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Comments
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {selectedData?.comment}
                  </ListItemText>
                </ListItem>
              </Grid>
            </Grid>
          </CardContent>
        </Card>}
        {showEdit && (
        <UpdateNomination
          showEdit={showEdit}
          close={() => props.close()}
          account={props.account}
        />
      )}
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
        </>
  )
}

export default NominationDetail