import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
// material ui 
import {Grid, Stack, Typography} from '@mui/material'
// components
import Layout from '../../layout/Layout'
import { MuiDataGrid, MuiAutocomplete, GridCellExpand } from '../../components'
// redux
import { getAllClients } from "../../features/customer/custUserSlice";
import { getCoachingFocusArea } from '../../features/coaching_focus_area/coachingFocusAreaSlice';
import { PageHeader } from '@excoleadershipui/pionweblib';

// -----------------------------------------------------

const CoachingFocusArea = () => {
  const [clientName, setClientName] = useState("");

  const { custClientsData } = useSelector((state) => ({
    ...state.custUser,
  }));

  const {coachingFocusAreaData} = useSelector((state) => ({...state.coachingFocusArea}))

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllClients())
  }, [])

  useEffect(() => {
    clientName && dispatch(getCoachingFocusArea(clientName?.excoCustomerUser?.id))
  }, [clientName])
 
  function renderCellExpand(params) {
      return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
      );
    }

  const data = {
    rows: coachingFocusAreaData,
    columns: [
      { field: "developmentArea", headerName: "Development Area", width: 200,renderCell: renderCellExpand },
      { field: "focus", headerName: "Coaching Focus", width: 200,renderCell: renderCellExpand },
      { field: "detailedDesc", headerName: "Description", width: 300,renderCell: renderCellExpand },
     
    ],
  };

  return (
    <Layout>
<Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",

          fontFamily: "arial",
          marginBottom: '2rem'
        }}
      >
        <Stack sx={{display:"flex",flexDirection:"row", alignItems: 'center',}}>
        <Grid container >
        <Grid item xs={12} sm={12} md={8}>
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "700"}}
          gutterBottom
          variant="h9"
          component="div"
        >
        Coaching Focus Area for
        </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
        <Stack sx={{ width: "10rem", marginLeft: "0.5rem" }}>
          <MuiAutocomplete
              name="clientName"
              options={custClientsData}
              setValue={(name, newValue) =>
                setClientName(newValue)
              }
              value={clientName}
              optionValue="firstName"
              optionSecValue="lastName"
              sx={{margin: 0, width: '15rem'}}
            />
        </Stack>
        </Grid>
        </Grid>
        </Stack>
        </Stack>
        {clientName && <PageHeader variant="pageHeader">{clientName?.firstName} {clientName?.lastName} from {clientName?.excoCustomerUser?.excoAccount?.accountName}, Coaching Focus</PageHeader>}
        {clientName && <MuiDataGrid 
            toolbarColumn = {true}
            toolbarFilter = {true}
            toolbarDensity = {true}
            toolbarExport = {true} 
            data={data} 
            />}
    </Layout>
  )
}

export default CoachingFocusArea