import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

// material ui
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  Stack,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
// components
import { MuiDataGrid, GridCellExpand, ConfirmDialog, Notifier, ErrorNotifier} from "../../../components";
import {
  UpdateEngagement,
  AddKeyEngagements,
  UpdateKeyEngagements,
  AddNetPromoterScore,
  UpdateNetPromoterScore,
} from "../engagements";
// redux
import {
  getKeyEngagement,
  deleteKeyEngagement,
  rowSelect as keyEngRowSelect,
  resetMessage as keyEngResetMessage,
  resetErrMessage as keyEngResetErrMessage,
} from "../../../features/exec/engagement/keyEngagementSlice";
import {
  getCustUserNetPromoterScore,
  deleteNetPromoterScore,
  rowSelect as netScoreRowSelect,
  resetMessage as netScoreResetMessage,
  resetErrMessage as netScoreResetErrMessage,
} from "../../../features/exec/engagement/netPromoterScoreSlice";

// --------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
  },
}));

// -----------------------------------------------------------------------------

const EngagementDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [showKeyEngAdd, setShowKeyEngAdd] = useState(false);
  const [showKeyEngEdit, setShowKeyEngEdit] = useState(false);
  const [keyEngConfirmDialog, setKeyEngConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showNetScoreAdd, setShowNetScoreAdd] = useState(false);
  const [showNetScoreEdit, setShowNetScoreEdit] = useState(false);
  const [netScoreConfirmDialog, setNetScoreConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [engStageArr, setEngStageArr] = useState([]);


  const classes = useStyles();

  const location = useLocation()

  const dispatch = useDispatch();
  const keyEngResp = useSelector((state) => ({ ...state.keyEngagement }));
  const netScoreResp = useSelector((state) => ({ ...state.netPromoterScore }));
  const { engStageData } = useSelector((state) => ({ ...state.engStage }));

  function getEngDate(params) {
    return params?.row?.engagementDate ? format(new Date(params?.row?.engagementDate), "MM-dd-yyyy") : "";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
  }


  const keyEngagementData = {
    rows: keyEngResp.keyEngagementData,
    columns: [
      {
        field: "engagementStageName",
        headerName: "Engagement Stage Name",
        width: 200,
      },
      {
        field: "engagementDate",
        headerName: "Engagement Date",
        width: 150,
        valueGetter: getEngDate,
      },
      { field: "status", headerName: "Status", width: 100 },
      { field: "comments", headerName: "Comments", width: 250, renderCell: renderCellExpand },
    ],
  };

  const netScoreData = {
    rows: netScoreResp.netPromoterScoreData,
    columns: [
      { field: "npsRatingScore", headerName: "Rating Score", width: 150 },
      { field: "npsRatingName", headerName: "Rating Name", width: 150 },
      { field: "comments", headerName: "Comments", width: 300, renderCell: renderCellExpand },
    ],
  };

  const handleDeleteKeyEng = () => {
    let data = {
      id: keyEngResp.selectedData?.id,
    };
    dispatch(deleteKeyEngagement(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          setKeyEngConfirmDialog({
            ...keyEngConfirmDialog,
            isOpen: false,
          });
          dispatch(keyEngResetMessage());
          dispatch(getKeyEngagement(props?.values?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(keyEngResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetKeyEngConfirmDialog = (obj) => {
    setKeyEngConfirmDialog({
      ...keyEngConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const handleDeleteNetScore = () => {
    let data = {
      id: netScoreResp.selectedData?.id,
    };

    let npsData = {
      excoCustomerUserId: props?.values?.excoCustomerUser?.id,
    };

    dispatch(deleteNetPromoterScore(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(netScoreResetMessage());
          setNetScoreConfirmDialog({
            ...netScoreConfirmDialog,
            isOpen: false,
          });
          dispatch(getCustUserNetPromoterScore(npsData));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(netScoreResetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetNetScoreConfirmDialog = (obj) => {
    setNetScoreConfirmDialog({
      ...netScoreConfirmDialog,
      isOpen: obj.isOpen,
    });
  };

  useEffect(() => {
    let data = {
      excoCustomerUserId: props?.values?.excoCustomerUser?.id,
    };
    dispatch(getCustUserNetPromoterScore(data));
    dispatch(getKeyEngagement(props?.values?.id));
    
    let engStages = [];
    engStageData.length > 0 &&
      engStageData.map((item) => {
        if (item?.engagementTier?.engagementTierName === props?.values?.engTierName) {
          return engStages.push(item);
        }
      });
    setEngStageArr(engStages.sort((a,b) => Number(a.engagementStageSequenceNo) - Number(b.engagementStageSequenceNo)));
  }, []);


  return (
    <>
      {!edit && (
        <Card sx={{ marginTop: "0.5rem" }}>
          <CardHeader
            title={
              <PageHeader variant="pageHeader">Engagement Details</PageHeader>
            }
            action={
              <IconButton aria-label="cancel" onClick={() => props.close()}>
                <CancelIcon color="red" />
              </IconButton>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5}>
                <Stack flexDirection="row" justifyContent="space-between">
                  <PageHeader variant="pageSecHeader">
                    {props?.values?.engagementStatus}
                  </PageHeader>
                  {location?.state?.permissions?.find((e) => e.applicationService?.name?.split(".")[e?.applicationService?.name?.split(".")?.length - 1] === "update") &&
                  <IconButton onClick={() => setEdit(true)}>
                    <EditIcon color="#00527d" />
                  </IconButton>
  }  
                </Stack>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Account Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.excoCustomerUser?.excoAccount?.accountName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Cust Client Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.firstName
                    }
                    &nbsp;
                    {
                      props?.values?.excoCustomerUser?.excoCustomerUserDetail
                        ?.lastName
                    }
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Engagement Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText style={{wordBreak:'break-word'}} className={classes.muiListDataText}>
                    {props?.values?.engagementName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Engagement Scope
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.engagementScope}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Eng Tier Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.engTierName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Eng Start Date
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {format(
                      new Date(props?.values?.engagementStartDate),
                      "MM-dd-yyyy"
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Name
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.contactName}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Email
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText style={{wordBreak:'break-word'}} className={classes.muiListDataText}>
                    {props?.values?.contactEmail}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Contact Number
                  </ListItemText>
                  &nbsp; :&nbsp;
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.dialingCode}-{props?.values?.contactNumber}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Engagement Status
                  </ListItemText>
                  &nbsp; :&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.engagementStatus}
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.muiList}>
                  <ListItemText className={classes.muiListHeadText}>
                    Comments
                  </ListItemText>
                  &nbsp;:&nbsp;{" "}
                  <ListItemText className={classes.muiListDataText}>
                    {props?.values?.comments}
                  </ListItemText>
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <PageHeader variant="pageSecHeader">Key Engagements</PageHeader>
                <MuiDataGrid
                  setShowAdd={() => setShowKeyEngAdd(true)}
                  setShowEdit={() => setShowKeyEngEdit(true)}
                  setShowDelete={() =>
                    setKeyEngConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDeleteKeyEng(),
                    })
                  }
                  toolbarColumn = {true}
                  toolbarFilter = {true}
                  toolbarDensity = {true}
                  toolbarExport = {true}
                  data={keyEngagementData}
                  select={(data) => dispatch(keyEngRowSelect(data))}
                  height={300}
                />
                <Box sx={{ marginTop: "0.5rem" }}>
                  <PageHeader variant="pageSecHeader">
                    Net Promoter Score
                  </PageHeader>
                </Box>
                <MuiDataGrid
                  setShowAdd={() => setShowNetScoreAdd(true)}
                  setShowEdit={() => setShowNetScoreEdit(true)}
                  setShowDelete={() =>
                    setNetScoreConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDeleteNetScore(),
                    })
                  }
                  select={(data) => dispatch(netScoreRowSelect(data))}
                  toolbarColumn = {true}
                  toolbarFilter = {true}
                  toolbarDensity = {true}
                  toolbarExport = {true}
                  data={netScoreData}
                  height={300}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {edit && (
        <UpdateEngagement close={() => props.close()} values={props.values} />
      )}
      {showKeyEngAdd && (
        <AddKeyEngagements
          showAdd={showKeyEngAdd}
          closeModel={() => setShowKeyEngAdd(false)}
          engagement={props?.values?.id}
          engStartDate={props?.values?.engagementStartDate}
          custClient={props?.values?.excoCustomerUser?.id}
          engStageArr={engStageArr}
        />
      )}
      {showKeyEngEdit && (
        <UpdateKeyEngagements
          showEdit={showKeyEngEdit}
          closeModel={() => setShowKeyEngEdit(false)}
          engagement={props?.values?.id}
          engStartDate={props?.values?.engagementStartDate}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      {showNetScoreAdd && (
        <AddNetPromoterScore
          showAdd={showNetScoreAdd}
          closeModel={() => setShowNetScoreAdd(false)}
          engagement={props?.values?.id}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      {showNetScoreEdit && (
        <UpdateNetPromoterScore
          showEdit={showNetScoreEdit}
          closeModel={() => setShowNetScoreEdit(false)}
          engagement={props?.values?.id}
          custClient={props?.values?.excoCustomerUser?.id}
          tierName={props?.values?.engTierName}
        />
      )}
      <ConfirmDialog
        confirmDialog={keyEngConfirmDialog}
        setConfirmDialog={(obj) => handleSetKeyEngConfirmDialog(obj)}
      />
      <ConfirmDialog
        confirmDialog={netScoreConfirmDialog}
        setConfirmDialog={(obj) => handleSetNetScoreConfirmDialog(obj)}
      />

{keyEngResp?.errMessage && <ErrorNotifier message={keyEngResp?.errMessage} />}
      {keyEngResp?.message && <Notifier message={keyEngResp?.message} />}

{netScoreResp?.errMessage && <ErrorNotifier message={netScoreResp?.errMessage} />}
      {netScoreResp?.message && <Notifier message={netScoreResp?.message} />}
    </>
  );
};

export default EngagementDetails;
