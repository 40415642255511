import React from "react";
import { PageHeader, MyTextInput } from "@excoleadershipui/pionweblib";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
// material ui
import {
  Card,
  CardHeader,
  IconButton,
  Grid,
  Box,
  CardContent,
} from "@mui/material";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
// components
import { FormSubmitButton } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
// redux
import {
  createContactDesignation,
  getContactDesignation,
  resetMessage,
  resetErrMessage,
} from "../../../features/config/contactDesignationSlice";

// --------------------------------------------------------------

const validationSchema = Yup.object({
  designationCode: Yup.string().required("Designation Code is required"),
  designationName: Yup.string().required("Designation Name is required"),
  comments: Yup.string(),
});

//   ------------------------------------------------------------------------------------------

const AddContactDesignation = (props) => {
  const {  message, errMessage } = useSelector((state) => ({
    ...state.contactDesig,
  }));

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      designationCode: "",
      designationName: "",
      comments: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        createContactDesignation({
          designationCode: values.designationCode,
          designationName: values.designationName,
          comments: values.comments,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            props.close();
            dispatch(getContactDesignation());
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  return (
    <Card sx={{ marginTop: "0.5rem" }}>
      <CardHeader
        title={
          <PageHeader variant="pageHeader">Add Contact Designation</PageHeader>
        }
        action={
          <IconButton aria-label="cancel" onClick={() => props.close()}>
            <CancelIcon color="red" />
          </IconButton>
        }
      />
      <CardContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
              <MyTextInput
                label="Designation Code"
                type="text"
                name="designationCode"
                required={true}
                fullWidth={true}
                margin="normal"
                id="designationCode"
                value={formik.values.designationCode}
                onChange={formik.handleChange}
                error={
                  formik.touched.designationCode &&
                  Boolean(formik.errors.designationCode)
                }
                helperText={
                  formik.touched.designationCode &&
                  formik.errors.designationCode
                }
              />
              <MyTextInput
                label="Designation Name"
                type="text"
                name="designationName"
                required={true}
                fullWidth={true}
                margin="normal"
                id="designationName"
                value={formik.values.designationName}
                onChange={formik.handleChange}
                error={
                  formik.touched.designationName &&
                  Boolean(formik.errors.designationName)
                }
                helperText={
                  formik.touched.designationName &&
                  formik.errors.designationName
                }
              />
              <MyTextInput
                label="Comments"
                type="text"
                name="comments"
                fullWidth={true}
                margin="normal"
                id="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <Box textAlign="center">
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                >
                  Submit
                </FormSubmitButton>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
          </Grid>
        </form>
      </CardContent>
      {message && <Notifier message={message} />}
      {errMessage && <ErrorNotifier message={errMessage} />}
    </Card>
  );
};

export default AddContactDesignation;
