import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useFormik } from "formik";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
// material ui
import {Grid,Box, Paper, Typography, Button, Stack, FormControl, TextField, InputAdornment, Select, MenuItem, Accordion, AccordionDetails, InputLabel, FormHelperText, useMediaQuery } from "@mui/material"
import { makeStyles } from "@mui/styles";
// components
import Layout from "../../layout/Layout";
import { MuiDataGridForSummary, MuiAutocomplete, ModalPopUp, ModalCloseBtn, FormSubmitButton, Notifier, ErrorNotifier, GridCellExpand   } from "../../components";
//redux-toolkit
import {
  getClientCheckinReports,
  updateEngagementNotes,
  updateFocusCheckinReports,
  addReportsFocus,
  resetMessage,
  getLatestSummaryRecords,
  getMentorReportsDateById,
  getMentorSummaryDateReports,
  resetErrMessage,
  rowSelect,
  emptyingArry
} from "../../features/summary/summarySlice";
import { getAllClients } from "../../features/customer/custUserSlice";

// --------------------------------------------------------------------------------------------------

const DonutChart = ({dataReports}) => {
  return (
    <>
      <Doughnut data={dataReports} options={dataReports.options} />
    </>
  );
}

const Summary = () => {
  const [clientName, setClientName] = useState("");
  const [checkInRating, setCheckinRating] = useState(null);
  const [checkinValue, setCheckinValue] = useState(null);
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showCoachingAdd, setShowCoachingAdd] = useState(false);
  const [showUpdateCoachingAdd, setShowUpdateCoachingAdd] = useState(false);
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const {
    message,
    errMessage,
    getSummaryDateReports,
    selectedData,
    generateSummaryData,
  } = useSelector((state) => ({ ...state.summary }));

  const { custClientsData } = useSelector((state) => ({
    ...state.custUser,
  }));

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],

        needleValue: checkInRating,
        backgroundColor: ["red", "#FFCE56", "lightgreen"],
        hoverBackgroundColor: ["red", "#FFCE56", "lightgreen"],
      },
    ],
    options: {
      layout: {
      },
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - 200;
      var cx = cw / 2;
      var cy = ch + 95;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };


  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      engagementNote: "",
    },

    onSubmit: (values) => {
     
    },
  });


  
  let engagementNoteTextLength = formik?.values?.engagementNote?.length

  const updateEngagementformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      engagementNote: selectedData?.engagementNote,
    },

    onSubmit: (values) => {
      let data = {
        id: selectedData?.id,
        excoCustomerUserId: clientName?.excoCustomerUser?.id,
        engagementNote: values.engagementNote,
      };
      dispatch(updateEngagementNotes(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeUpdateEngagementModel();
            dispatch(getClientCheckinReports(clientName?.excoCustomerUser?.id));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            closeModel();
          }, 3000);
        }
      });
    },
  });

  let updateEngagementNoteTextLength = updateEngagementformik?.values?.engagementNote?.length

  const coachingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      progressTheme: "",
      progressDescription: "",
    },

    onSubmit: (values) => {
      let data = {
        excoCustomerUserId: clientName?.excoCustomerUser?.id,
        progressTheme: values.progressTheme,
        progressDescription: values.progressDescription,
      };
      dispatch(addReportsFocus(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeCoachingModel();
            dispatch(getClientCheckinReports(clientName?.excoCustomerUser?.id));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            closeModel();
          }, 3000);
        }
      });
    },
  });

  let coachingFormikLength = coachingFormik?.values?.progressDescription?.length

  const updateCoachingFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      progressTheme: selectedData?.progressTheme,
      progressDescription: selectedData?.progressDescription,
    },

    onSubmit: (values) => {
      let data = {
        id: selectedData?.id,
        excoCustomerUserId: clientName?.excoCustomerUser?.id,
        progressTheme: values.progressTheme,
        progressDescription: values.progressDescription,
      };
      dispatch(updateFocusCheckinReports(data)).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeUpdateCoachingModel();
            dispatch(getClientCheckinReports(clientName?.excoCustomerUser?.id));
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
            closeModel();
          }, 3000);
        }
      });
    },
  });

  let updateCoachingFormikLength = updateCoachingFormik?.values?.progressDescription?.length

  const closeModel = () => {
    setShowAdd(false);
    formik.resetForm({
      values: {
        engagementNote: "",
      },
    });
  };

  const closeUpdateEngagementModel = () => {
    setShowUpdate(false);
    updateEngagementformik.resetForm({
      values: {
        engagementNote: "",
      },
    });
  };

  const closeCoachingModel = () => {
    setShowCoachingAdd(false);
    coachingFormik.resetForm({
      values: {
        progressTheme: "",
        progressDescription: "",
      },
    });
  };

  const closeUpdateCoachingModel = () => {
    setShowUpdateCoachingAdd(false);
    updateCoachingFormik.resetForm({
      values: {
        progressTheme: "",
        progressDescription: "",
      },
    });
  };

  const useStyles = makeStyles((theme) => ({
    cell: {
      whiteSpace: "none !important",
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
        box_margin: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "-2rem !important",
        marginTop: "0.5rem",
        width:'21.875rem'
      },
    },
    img_margin : {
      [theme.breakpoints.down("md")]: {
        marginTop:'0px !important'
      }
    },
    commentbox_width : {
      width:'25rem',
      marginLeft:'3rem',
      [theme.breakpoints.down("md")]: {
        width:'17rem',
        marginLeft:'0rem'
      }
    },
  }));
  const classes = useStyles();

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const dataTwo = {
    rows:
      Object.keys(generateSummaryData).length > 0 ? generateSummaryData.cfa : [],
    columns: [
      {
        field: "progressDescription",
        headerName: "Description",
        width: 400,
        renderCell: renderCellExpand,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };



  const dataThree = {
    rows:
      Object.keys(generateSummaryData).length > 0
        ? generateSummaryData.notes
        : [],
    columns: [
      {
        field: "engagementNote",
        headerName: "Engagement Notes",
        renderCell: renderCellExpand,
        width: 400,
      },
    ],
  };

  const handleCheckinChange = (e) => {
    if (e.target.value === 1) {
      setCheckinRating(150);
      setCheckinValue(e.target.value);
    } else if (e.target.value === 2) {
      setCheckinRating(450);
      setCheckinValue(e.target.value);
    } else if (e.target.value === 3) {
      setCheckinRating(750);
      setCheckinValue(e.target.value);
    }
  };

  useEffect(() => {
    let result =
      Object.keys(generateSummaryData).length > 0 &&
      generateSummaryData.checkin[0].engagementCheckInRating &&
      generateSummaryData.checkin[0].engagementCheckInRating;
      console.log(result)
    if (result === 1) {
      console.log(result, "first");
      setCheckinRating(150);
    } else if (result === 2) {
      setCheckinRating(450);
      console.log(result, "second");
    } else if (result === 3) {
      setCheckinRating(750);
      console.log(result, "third");
    } else {
      setCheckinRating(null);
    }
  }, [generateSummaryData]);


  useEffect(() => {
    dispatch(getAllClients());
  }, []);
  
  useEffect(() => {
    let result =
      Object.keys(generateSummaryData).length > 0 &&
      generateSummaryData.checkin[0].checkInFromDate &&
      generateSummaryData.checkin[0].checkInFromDate;

    let resultTwo =
      Object.keys(generateSummaryData).length > 0 &&
      generateSummaryData.checkin[0].checkInToDate &&
      generateSummaryData.checkin[0].checkInToDate;

    console.log(result, resultTwo);

    setStartDate(result ? format(new Date(result), "yyyy-MM-dd") : "");
    setEndDate(resultTwo ? format(new Date(resultTwo), "yyyy-MM-dd") : "");
  }, [generateSummaryData]);

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
    dispatch(getMentorSummaryDateReports(clientName?.excoCustomerUser?.id));
  };

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);
    setGenerateRowId(e.target.value);
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientEngagementCheckinId: generateDateValue
    };

    dispatch(getMentorReportsDateById(body)).then((res) => {
      closeShowModelGenerate();
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };
   const matches2 = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMappingGrpah = () => {
    if (Object.keys(generateSummaryData).length > 0) {
      let data = [];
      const items = [];
      for (const res of generateSummaryData?.ratings) {
        for (const res1 of res.mentorToClientRating) {
          const date = new Date(res1.clientProgressReview.reviewDate);
          const transformDate =
            date.getMonth() +
            1 +
            "-" +
            date.getDate() +
            "-" +
            date.getFullYear() +
            " UTC";

          let rate;
          if (res1.rating === 100) rate = 2;
          if (res1.rating === 80) rate = 1;
          if (res1.rating === 60) rate = 0;
          if (res1.rating === 40) rate = -1;
          if (res1.rating === 20) rate = -2;
          if (res1.rating === 1) rate = -3;

          data.push({
            x: transformDate,
            y: rate,
          });
        }


        const options = {
          colors: ["#000"],
          tooltip: {
            enabled: false,
          },
          chart: {
            height: 380,
            width: "100%",
            type: "line",
            animations: {
              initialAnimation: {
                enabled: true,
              },
            },
          },
          series: [
            {
              data,
            },
          ],
          markers: {
            size: 5,
            colors: "#000000",
            strokeColors: "#111111",
            strokeWidth: 15,
            strokeOpacity: 0.2,
            fillOpacity: 0.7,
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels:{
              formatter: function (val, index) {
                if (val === -3) return "N/A";
                return val;
              }
            },
            min: -3,
            max: 2,
            tickAmount: 5,
          },
        };
        data = [];
        let finalWidth = matches2 ? "250" : "450"
        items.push(
          {
            id:res.id,
            name:res.focus,
            val: (<Chart
            options={options}
            series={options.series}
            type="line"
            width={finalWidth}
            height="200"
          />)
          }
        );
      }

      return (
        <>
          {items.map((data) => {
            return  <Stack key={data?.id} >
            <Typography  sx={{ fontSize: "1rem", fontWeight: "bold", marginTop: "0.5rem" }}>{data.name}</Typography>
            <Paper sx={{ marginTop: "1rem" }}>{data.val}</Paper>
          </Stack>
          })}
        </>
      );
    }
  };


  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlViewMoreClick = (id) => {
    setCommentId(id);
  };

  useEffect(() => {
    dispatch(emptyingArry())
    clientName &&  dispatch(getLatestSummaryRecords(clientName?.excoCustomerUser?.id));
    setTimeout(()=>{
      dispatch(resetErrMessage())
       },2000)
    }, [clientName]);

  
  useEffect(() => {

    let result =

      Object.keys(generateSummaryData).length > 0 &&

      generateSummaryData.checkin[0].engagementCheckInRating &&

      generateSummaryData.checkin[0].engagementCheckInRating;


    if (result === 1) {

    setCheckinRating(150);

    setCheckinValue("Red")



    } else if (result === 2) {

      setCheckinRating(450);

      setCheckinValue("Yellow")

   

    } else if (result === 3) {

      setCheckinRating(750);

      setCheckinValue("Green")

    } else {

      setCheckinRating(null);

      setCheckinValue("")

    }

  }, [generateSummaryData]);

  return (
    <Layout>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: "arial",
        }}
      >
       
         <Grid container >
         <Grid item xs={12} sm={12} md={3}>
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "700" ,marginTop: "0.75rem",}}
          gutterBottom
          variant="h9"
          component="div"
        >
          Engagement Checkin
        </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
    
          <MuiAutocomplete
              name="clientName"
              options={custClientsData}
              setValue={(name, newValue) => setClientName(newValue)}
              value={clientName}
              optionValue="firstName"
              optionSecValue="lastName"
              sx={{margin: "0 !important", width: '11rem'}}
          />
       
        </Grid>
       
     <Grid item xs={12} sm={12} md={6}>
        {clientName && Object.keys(generateSummaryData).length > 0 && <Stack
          sx={{ display: "flex", flexDirection: "column", height: "3.5rem" }}
        >
          <Stack
            sx={{ display: "flex", flexDirection: "row", alignItems: "center",marginLeft: "4.50rem"  }}
            className={classes.box_margin}
          >
            <TextField
              sx={{ marginLeft: "2rem" }}
              type="date"
              name="startDate"
              id="demo-simple-select"
              required={true}
              value={startDate}
              onChange={handleStartChange}
              disabled={true}
            />
            <Typography
              sx={{ fontSize: "1rem", margin: '0 0.5rem' }}
              gutterBottom
              variant="h9"
              component="div"
            >
              To
            </Typography>
            <TextField
              type="date"
              name="endDate"
              id="demo-simple-select"
              required={true}
              value={endDate}
              onChange={handleEndChange}
              disabled={true}
            />
          </Stack>
        </Stack> }
        </Grid>
 <Grid item xs={12} sm={12} md={2}>
       {clientName && Object.keys(generateSummaryData).length > 0 && <FormSubmitButton
          type="submit"
          variant="contained"
          onClick={handleShowPreviousOutcomes}
          sx={{textTransform : 'none', marginTop: "0.75rem"}}
          
        >
          Previous Summary
        </FormSubmitButton>}
        </Grid>
         </Grid>
      </Stack>

      <ModalPopUp
        open={showModelGenerate}
        handleClose={closeShowModelGenerate}
        closeModel={closeShowModelGenerate}
      >
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
        >
          <ModalCloseBtn close={closeShowModelGenerate} />

          <div style={{ width: "50%", marginTop: "1rem" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Date</InputLabel>
              <Select
                type="select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="generateDateValue"
                value={generateDateValue}
                label="Geography"
                onChange={handleDateGenerateChange}
              >
                {getSummaryDateReports.length > 0 &&
                  getSummaryDateReports.map((itemValue) => {
                    return (
                      <MenuItem key={itemValue.id} value={itemValue.id}>
                        {format(
                          new Date(itemValue.checkInFromDate),
                          "MM-dd-yyyy"
                        )}{" "}
                        to {
                       format(
                        new Date(itemValue.checkInToDate),
                        "MM-dd-yyyy"
                      ) }
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>

          <Stack sx={{ marginTop: "1rem" }}>
            <FormSubmitButton
              type="submit"
              variant="contained"
              onClick={handleGenerateDateSubmit}
            >
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
      </ModalPopUp>

      {clientName && Object.keys(generateSummaryData).length > 0 && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "2rem",
          }}
        >
        <Grid container>
        <Grid item xs={12} sm={12} md={6}>
            {clientName && Object.keys(generateSummaryData).length > 0 && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "2rem",
                  fontFamily: "arial",
                }}
              >
                <Typography
                  sx={{ fontSize: "1.25rem", fontWeight: "bold" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  LEVEL OF ENGAGEMENT
                </Typography>

                <Stack sx={{ width: "10rem", marginLeft: "1rem" }}>
                  <FormControl size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleCheckinChange}
                      value={checkinValue}
                      size="large"
                      disabled={true}
                    >
                      <MenuItem key={1} value="Red">
                        {" "}
                        Red{" "}
                      </MenuItem>
                      <MenuItem key={2} value="Yellow">
                        {" "}
                        Yellow{" "}
                      </MenuItem>
                      <MenuItem key={3} value="Green">
                        {" "}
                        Green{" "}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            )}
            <Stack sx={{ marginTop: "-6rem" }} className={classes.img_margin}>
              <DonutChart  dataReports={dataReports} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {clientName && Object.keys(generateSummaryData).length > 0 && (
              <PageHeader variant="pageHeader">Engagement Notes</PageHeader>
            )}
            <Stack sx={{}}>
              {clientName && Object.keys(generateSummaryData).length > 0 && (
                <MuiDataGridForSummary
                  select={(dataThree) => dispatch(rowSelect(dataThree))}
                  viewAdd={true}
                  data={dataThree}
                />
              )}
            </Stack>
      </Grid>
          </Grid>
        </Stack>
      )}

{clientName && Object.keys(generateSummaryData).length > 0 && <Stack sx ={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
           <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "arial",
          
          }}
        >
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            Engagement Key
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Green</span>{" "}
            Highly engaged with coach/set meetings regulary/leaning in.
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Yellow</span>{" "}
            Engaged when meeting but challenging to schedule with.
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>Red</span>{" "}
            Goes long period without meeting with coach/not engaged.
          </Typography>
        </Stack>

        <Stack sx={{marginLeft:"2rem"}}>
         <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "arial",
           
          }}
        >

      
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
            gutterBottom
            variant="h9"
            component="div"
          >
          Impact Scale Rating
          </Typography>
         <Stack sx={{display:"flex",flexDirection:"row"}}>
          <Stack>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>N/A</span>{" "}
            Priorities Shifted,and this is no longer applicable
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>-2</span>{" "}
       Significant deterioration in outcome
          </Typography>
        
         
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>-1</span>{" "}
            Slight deterioration in outcome
          </Typography>
          </Stack>
          <Stack sx={{marginLeft:"1rem"}}>
        

          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>0</span>{" "}
       No change in outcome
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>1</span>{" "}
       Achieved expected outcome
          </Typography>
          <Typography
            sx={{ fontSize: "0.75rem" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            <span style={{ fontSize: "1rem", fontWeight: "bold" }}>2</span>{" "}
            Greater than expected outcome
          </Typography>
          </Stack>
          </Stack>
        </Stack> 
        </Stack>
       
        </Stack>}

      {clientName && Object.keys(generateSummaryData).length > 0 && generateSummaryData?.ratings?.length > 0 && (
        <Stack sx={{ marginTop: "1rem" }}>
          <PageHeader variant="pageHeader">Impact of Coaching</PageHeader>
        </Stack>
      )}

      {clientName && Object.keys(generateSummaryData).length > 0 && generateSummaryData?.ratings?.length > 0 && (
     
          <Grid container>

          <Grid item xs={12} sm={12} md={6}>
          <Stack >
            {isMappingGrpah()}
          </Stack>
          </Grid>
         
          <Stack>
            {clientName && Object.keys(generateSummaryData).length > 0 &&
              generateSummaryData?.ratings?.map((data) => {
                return (
                  <Paper
                    key={data.id}
                    sx={{ height: 215, marginTop: "3rem", overflow: "auto" }}
                    className={classes.commentbox_width}
                    elevation={3}
                  >
                     <Grid item xs={12} sm={12} md={6}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      Comments
                    </Typography>

                    <Stack sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "1rem", fontWeight: "bold" }}
                        >
                          {data.focus}
                        </Typography>
                      </Stack>
                      {commentId != data.id && (
                        <Stack sx={{paddingBottom:"1rem"}}>
                        <Accordion>
                          <AccordionDetails>
                            <Typography>
                              {data.mentorToClientRating.length > 0 &&
                                data.mentorToClientRating.at(-1).comment}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        </Stack>
                      )}
                      {commentId != data.id && data.mentorToClientRating.length > 1 &&  (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginTop: "0.5rem",
                            paddingBottom:"1rem"
                          }}
                        >
                          <Button
                            onClick={() => handlViewMoreClick(data.id)}
                            size="small"
                            variant="contained"
                          >
                            View More
                          </Button>
                        </Stack>
                      )}
                      {commentId === data.id &&
                        data.mentorToClientRating.length > 0 &&
                        data.mentorToClientRating.map((dataTwo) => {
                          return (
                            <Accordion sx={{ marginTop: "1rem" }}>
                              <AccordionDetails>
                                <Typography>
                                  {dataTwo?.comment}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                    </Stack>
                    </Grid>
                  </Paper>
                );
              })}
          </Stack>
     
          </Grid>
        
      )}


      <Stack sx={{ marginTop: "2rem" }}>
        <ModalPopUp
          open={showAdd}
          handleClose={closeModel}
          closeModel={closeModel}
        >
          <Box
            sx={{ ...style, width: 700 }}
            component="form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <ModalCloseBtn close={closeModel} />
            <TextField
                InputProps={{
                  endAdornment: <InputAdornment sx={{paddingBottom:"2rem",fontSize:"0.8rem"}}>{500-engagementNoteTextLength} Characters remaining</InputAdornment>,
              }}
              sx={{ width: "100%", height: "5rem" }}
              label="Engagement Notes"
              type="text"
              name="engagementNote"
              required={true}
              margin="normal"
              variant="filled"
              id="engagementNote"
              value={formik.values.engagementNote}
              onChange={formik.handleChange}
              helperText={formik.touched.engagementNote && formik.errors.engagementNote ||
                engagementNoteTextLength >500 && <FormHelperText error={true} sx={{ color: "red" }}>Description should have less than 500 characters</FormHelperText>
              }
           
            />

          </Box>
        </ModalPopUp>
        <ModalPopUp
          open={showUpdate}
          handleClose={closeUpdateEngagementModel}
          closeModel={closeUpdateEngagementModel}
        >
          <Box
            sx={{ ...style, width: 700 }}
            component="form"
            noValidate
            onSubmit={updateEngagementformik.handleSubmit}
          >
            <ModalCloseBtn close={closeUpdateEngagementModel} />
            <TextField
              InputProps={{
                endAdornment: <InputAdornment sx={{paddingBottom:"2rem",fontSize:"0.8rem"}}>{500-updateEngagementNoteTextLength} Characters remaining</InputAdornment>,
            }}
              sx={{ width: "100%", height: "5rem" }}
              label="Engagement Notes"
              type="text"
              name="engagementNote"
              required={true}
              margin="normal"
              variant="filled"
              id="engagementNote"
              value={updateEngagementformik.values.engagementNote}
              onChange={updateEngagementformik.handleChange}
              helperText={updateEngagementformik.touched.engagementNote && updateEngagementformik.errors.engagementNote ||
                updateEngagementNoteTextLength >500 && <FormHelperText error={true} sx={{ color: "red" }}>Description should have less than 500 characters</FormHelperText>
              }
              
            />

            <FormSubmitButton
              type="submit"
              variant="contained"
            >
              Update
            </FormSubmitButton>
          </Box>
        </ModalPopUp>
        {clientName && Object.keys(generateSummaryData).length > 0 && (
          <PageHeader variant="pageHeader">
            Coaching Summary – Additional Commentary
          </PageHeader>
        )}
        {clientName && Object.keys(generateSummaryData).length > 0 && (
          <MuiDataGridForSummary
            select={(dataTwo) => dispatch(rowSelect(dataTwo))}
            viewAdd={true}
            data={dataTwo}
          />
        )}
      </Stack>

      <Stack sx={{ marginTop: "2rem" }}>
        <ModalPopUp
          open={showCoachingAdd}
          handleClose={closeCoachingModel}
          closeModel={closeCoachingModel}
        >
          <Box
            sx={{ ...style, width: 700 }}
            component="form"
            noValidate
            onSubmit={coachingFormik.handleSubmit}
          >
            <ModalCloseBtn close={closeCoachingModel} />
            <TextField
              sx={{ width: "100%", height: "5rem" }}
              label="Progress Theme"
              type="text"
              name="progressTheme"
              required={true}
              margin="normal"
              variant="filled"
              id="progressTheme"
              value={coachingFormik.values.progressTheme}
              onChange={coachingFormik.handleChange}
            />

            <TextField
               InputProps={{
                endAdornment: <InputAdornment sx={{paddingBottom:"2rem",fontSize:"0.8rem"}}>{500- coachingFormikLength} Characters remaining</InputAdornment>,
            }}
              sx={{ width: "100%", height: "5rem" }}
              label="Description"
              type="text"
              name="progressDescription"
              required={true}
              margin="normal"
              variant="filled"
              id="progressDescription"
              value={coachingFormik.values.progressDescription}
              onChange={coachingFormik.handleChange}
              helperText={coachingFormik.touched.progressDescription && coachingFormik.errors.progressDescription ||
                coachingFormikLength >500 && <FormHelperText error={true} sx={{ color: "red" }}>Description should have less than 500 characters</FormHelperText>
              }
            />

            <FormSubmitButton
              type="submit"
              variant="contained"
            >
              Submit
            </FormSubmitButton>
          </Box>
        </ModalPopUp>
        <ModalPopUp
          open={showUpdateCoachingAdd}
          handleClose={closeUpdateCoachingModel}
          closeModel={closeUpdateCoachingModel}
        >
          <Box
            sx={{ ...style, width: 700 }}
            component="form"
            noValidate
            onSubmit={updateCoachingFormik.handleSubmit}
          >
            <ModalCloseBtn close={closeUpdateCoachingModel} />
            <TextField
              sx={{ width: "100%", height: "5rem" }}
              label="Progress Theme"
              type="text"
              name="progressTheme"
              required={true}
              margin="normal"
              variant="filled"
              id="progressTheme"
              value={updateCoachingFormik.values.progressTheme}
              onChange={updateCoachingFormik.handleChange}
            />

            <TextField
               InputProps={{
                endAdornment: <InputAdornment sx={{paddingBottom:"2rem",fontSize:"0.8rem"}}>{500- updateCoachingFormikLength} Characters remaining</InputAdornment>,
            }}
              sx={{ width: "100%", height: "5rem" }}
              label="Description"
              type="text"
              name="progressDescription"
              required={true}
              margin="normal"
              variant="filled"
              id="progressDescription"
              value={updateCoachingFormik.values.progressDescription}
              onChange={updateCoachingFormik.handleChange}
              helperText={updateCoachingFormik.touched.progressDescription && updateCoachingFormik.errors.progressDescription ||
                updateCoachingFormikLength >500 && <FormHelperText error={true} sx={{ color: "red" }}>Description should have less than 500 characters</FormHelperText>
              }
            />

            <FormSubmitButton
              type="submit"
              variant="contained"
            >
              Update
            </FormSubmitButton>
          </Box>
        </ModalPopUp>
      </Stack>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Summary;
